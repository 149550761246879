import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useAuth from './useAuth'; // useAuth hook'unu import edin


const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useAuth(); // useAuth hook'unu kullanın

  //console.log(isAuthenticated);

  if (!isAuthenticated) {
    // Kullanıcı doğrulanmamışsa, login sayfasına yönlendir
    return <Navigate to="/login" />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
