import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import '../css/Login.css';



const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null); // Hata mesajı için state
    
    const navigate = useNavigate(); // Kullanıcıyı yönlendirmek için

    const urlWPAPI = "https://hypereducation.net/wp-json/api/v1/token";


    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null); // Her login denemesinde hata mesajını sıfırla

        try {
            const response = await axios.post(urlWPAPI, {
                username,
                password
            });

            // Token'i local storage'a kaydet
            localStorage.setItem('token', response.data.jwt_token);

            // Kullanıcıyı AnaSayfa'ya yönlendir
            navigate('/');
        } catch (error) {
            // Login başarısız oldu, hata mesajını set et
            setError('Kullanıcı adı veya şifre hatalı!');
        }
    };

    return (
        <div className="login-container">
            <h1>Hızlı Okuma Kursu</h1>
            <div style={{ margin: '40px', }}></div>
            <form onSubmit={handleSubmit} className="login-form">
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Kullanıcı adı giriniz (e-posta değil)"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Şifre"
                />
                <button type="submit">Giriş</button>
            </form>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
    
};

export default Login;
