import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import Ball from './ball.png';
import PapatyaBeyaz from './PapatyaBeyaz.png';
import PapatyaMor from './Papatya-MorRenk-Transparan.png';
import KarTanesiMavi from './kartanesi-mavi.png';
import KarTanesiBeyaz from './kartanesi-beyaz.png';
import Yildiz from './yildiz.png';
import Gunes from './gunes.png';




const GozEgzersiziKoselerTekrar = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const selectedDay = location.state?.selectedDay;
    const icon = location.state?.selectedIcon;
    const selectedAnimationDuration = location.state?.selectedAnimationDuration;

    const [position, setPosition] = useState({ top: '20px', left: '20px' });
    const [positionsSequence, setPositionsSequence] = useState([]);
    const [showModal, setShowModal] = useState(false);


    const STEP_DURATION = selectedAnimationDuration * 1000;
    const TOTAL_STEPS = 15 / selectedAnimationDuration; // Toplam adım sayısı
    const ANIMATION_TIMEOUT = TOTAL_STEPS * STEP_DURATION; // Toplam animasyon süresi

    useEffect(() => {
        const positions = [
            { bottom: '20px', left: '20px' }, // Sol alt köşe
            { top: '20px', right: '20px' },   // Sağ üst köşe
            { bottom: '20px', right: '20px' }, // Sağ alt köşe
            { top: '20px', left: '20px' }     // Sol üst köşe
        ];

        let newPositionsSequence = [];
        let lastPositionIndex = -1;

        for (let i = 0; i < TOTAL_STEPS; i++) {
            let newIndex;
            do {
                newIndex = Math.floor(Math.random() * positions.length);
            } while (newIndex === lastPositionIndex);

            newPositionsSequence.push(positions[newIndex]);
            lastPositionIndex = newIndex;
        }

        setPositionsSequence(newPositionsSequence);

        let step = 0;
        const positionInterval = setInterval(() => {
            if (step < TOTAL_STEPS) {
                setPosition(newPositionsSequence[step]);
                step++;
            }
        }, STEP_DURATION);

        const stopAnimation = setTimeout(() => {
            clearInterval(positionInterval);
            setShowModal(true);
            // navigate('/sonrakiSayfa', { state: { selectedDay: selectedDay } }); // Animasyon bittiğinde yönlendirme
        }, ANIMATION_TIMEOUT);

        return () => {
            clearTimeout(stopAnimation);
            clearInterval(positionInterval);
        };
    }, []); 

    const modalStyle = {
        position: 'fixed', // Modalı sabitlemek için
        top: '50%', // Üstten yarı yarıya
        left: '50%', // Soldan yarı yarıya
        transform: 'translate(-50%, -50%)', // Tam ortalanmasını sağlamak için
        backgroundColor: 'white', // Modal arka plan rengi
        padding: '20px', // İçerik için padding
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Gölge efekti
        zIndex: 1000, // Diğer içeriklerin üstünde olması için
        borderRadius: '20px', 
    };
    
    const backdropStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Yarı saydam arka plan
        zIndex: 999 // Modalın hemen altında
    };
    

    const Modal = ({ show, onClose }) => {
        if (!show) return null;
    
        return (
            <>
                <div style={backdropStyle} onClick={onClose}></div> {/* Arka plan */}
                <div style={modalStyle}> {/* Modal içeriği */}
                    <h5>Göz Egzersizi Tamamlandı</h5>
                    <center><button style={{ marginTop: '20px' }} onClick={onClose}>Kapat</button></center>
                </div>
            </>
        );
    };


    const gitGunler = () => {
        navigate('/');
    };


    
    return (

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <h1 style={{ margin: '20px', textAlign: 'center' }}>Göz Egzersizi - {selectedDay}. Gün</h1>
            <div 
                style={{ 
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    background: '#E6E6FA', 
                    borderRadius: '30px', 
                    width: '940px',
                    height: '480px',
                }}
            >
                <div style={{ 
                    position: 'absolute',
                    top: position.top,
                    bottom: position.bottom,
                    left: position.left,
                    right: position.right,
                    width: '70px',
                    height: '70px',
                    backgroundImage: `url(${icon})`,
                    backgroundSize: 'cover',
                    borderRadius: '50%',
                    opacity: 1,
                }} />

            </div>

            <Modal show={showModal} onClose={() => setShowModal(false)} />

            {/* Kontrol Butonları */}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '50px', marginBottom: '50px' }}>
                <Button variant="primary" onClick={gitGunler}>Günler</Button>
            </div>

        </div>
        
    );
    
};

export default GozEgzersiziKoselerTekrar;

