import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { HiMiniPlay } from "react-icons/hi2";
import { HiMiniPause } from "react-icons/hi2";

import Button from 'react-bootstrap/Button';

import '../css/SchulteTablosu.css';



const SchulteTablosu = () => {

    const navigate = useNavigate();
    const location = useLocation();


    const verilenSure = 120;
    const selectedDay = location.state?.selectedDay;
    const basamakSayisi = location.state?.basamakSayisi;
    const bulunacakSayiAdedi = location.state?.bulunacakSayiAdedi;
    const columnsNumber = location.state?.columnsNumber;
    const rowsNumber = location.state?.rowsNumber;

    const [numbers, setNumbers] = useState([]);
    const [targetNumbers, setTargetNumbers] = useState([]);
    const [currentNumberIndex, setCurrentNumberIndex] = useState(0);
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [columns, setColumns] = useState(columnsNumber); // Sayıların sıralanacağı kolon sayısı
    const [rows, setRows] = useState(rowsNumber); // Sayıların sıralanacağı satır sayısı
    const [isPlaying, setIsPlaying] = useState(false); // Zamanlayıcının oynatma durumu için state
    const [key, setKey] = useState(0); // Sayaç süresini sıfırlamak için kullanılacak
    const [toplamBulunan, setToplamBulunan] = useState(0);
    const [bulunanSayilar, setBulunanSayilar] = useState(0);

    const [hasStarted, setHasStarted] = useState(false);



    useEffect(() => {
        generateRandomNumbers();
    }, []);


    const generateRandomNumbers = () => {
        let randomNumbers = [];
        let targetNumber;
        let max, min;
    
        if (basamakSayisi === 3) {
            min = 100;
            max = 999; // 999 dahil olmalı ki 100-999 arası tam 3 basamaklı sayılar olsun
        } else if (basamakSayisi === 4) {
            min = 1000;
            max = 9999; // 9999 dahil olmalı ki 1000-9999 arası tam 4 basamaklı sayılar olsun
        }
    
        // Rastgele bir hedef sayı seç
        targetNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    
        // Hedef sayının n kopyasını oluştur
        for (let i = 0; i < bulunacakSayiAdedi; i++) {
            randomNumbers.push({ value: targetNumber, isVisible: true });
        }
    
        // Geri kalan sayıları ekle
        while (randomNumbers.length < rows * columns) {
            const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
            if (randomNumber !== targetNumber) {
                randomNumbers.push({ value: randomNumber, isVisible: true });
            }
        }
    
        // Karıştır
        randomNumbers.sort(() => Math.random() - 0.5);
    
        setNumbers(randomNumbers);
        setTargetNumbers([targetNumber]);
    };


    const handleNumberClick = (number, index) => {
        if (isPlaying && number === targetNumbers[0] && numbers[index].isVisible) {
            // Yalnızca tıklanan sayının görünürlüğünü değiştir
            setNumbers(numbers.map((num, idx) => 
                idx === index ? { ...num, isVisible: false } : num
            ));
            
            // toplamBulunan'ı her doğru seçimde artır
            setToplamBulunan(prevToplam => prevToplam + 1);
            setBulunanSayilar(prevToplam => prevToplam + 1);
    
            // Yalnızca mevcut hedef sayının tüm kopyaları bulunduysa yeni sayı üret
            const bulunanSayiAdedi = numbers.reduce((count, current) => {
                return count + (current.value === number && !current.isVisible ? 1 : 0);
            }, 0);
    

            if (bulunanSayiAdedi + 1 === bulunacakSayiAdedi) {
                // Yeni bir hedef sayı oluştur
                generateRandomNumbers();
                setBulunanSayilar(0);
            }
        }
    };
    

    const handleClickStart = () => {
        generateRandomNumbers();
        setCurrentNumberIndex(0);
        setBulunanSayilar(0);
        setToplamBulunan(0);
        setIsPlaying(true); // Zamanlayıcıyı başlat
        setKey(prevKey => prevKey + 1); // Sayaç süresini sıfırla
        setHasStarted(true);
    };

    /* const handleClickStop = () => {
        setIsPlaying(false); // Zamanlayıcıyı durdur
        setCurrentNumberIndex(0);
    }; */

     // Pause/Play tuşuna basıldığında çağrılacak fonksiyon
     const handlePausePlay = () => {
        setIsPlaying(!isPlaying); // Oyunun oynatma durumunu tersine çevir
    };


    const gitGunler = () => {
        navigate('/');
    };


    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setIsPlaying(false); // Süre bittiğinde zamanlayıcıyı durdur
            return <h4 style={styles.blinkingText}>Süre Doldu</h4>;
        }
        
        return (
          <div className="timer">
            <div className="text">Kalan Süre</div>
            <div className="value">{Math.floor(remainingTime / 60)}:{String(remainingTime % 60).padStart(2, '0')}</div>
            <div className="text">dakika</div>
          </div>
        );
    };




    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

            <div>
                <h1 style={{ margin: '20px' }}><center>Schulte Tablosu - {selectedDay}. Gün</center></h1>

                <div style={{ padding:'20px', position: 'relative', display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`, gap: '1px', backgroundColor: '#f5f5f5', borderRadius: '30px', }}>

                    {numbers.map((numberObj, index) => (
                        <span
                            key={index}
                            style={{
                                margin: '1px',
                                cursor: isPlaying && numberObj.isVisible ? 'pointer' : 'default',
                                fontSize: '20px',
                                //color: numberObj.value === targetNumbers[0] && numberObj.isVisible ? 'red' : 'black',
                                visibility: numberObj.isVisible ? 'visible' : 'hidden',
                            }}
                            onClick={() => handleNumberClick(numberObj.value, index)}
                        >
                            {numberObj.value}
                        </span>
                    ))}

                </div>

                <div style={{ marginTop: '20px', backgroundColor: '#4ad3e7', padding: '10px', borderRadius: '15px', }}>
                    <span style={{ margin: '10px'}}>Hedef Sayı: <strong style={{ fontSize: '20px' }}>{targetNumbers[currentNumberIndex]}</strong> (bu sayıyı tabloda bulunuz)</span>
                    <span style={{ marginLeft: '220px', marginRight: '20px' }}>Hedef: {bulunanSayilar}/{bulunacakSayiAdedi} - Toplam Bulunan: {toplamBulunan} </span>
                </div>

                {/* Kontrol Butonları ve Timer */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    
                    {/* Başlat Butonu */}
                    <Button variant="success" onClick={handleClickStart}>Başlat</Button>
                    
                    {/* Timer Wrapper */}
                    <div className="timer-wrapper">
                        <CountdownCircleTimer
                            key={key}
                            isPlaying={isPlaying}
                            duration={verilenSure}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[verilenSure, verilenSure / 2, verilenSure / 3, 0]}
                            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                    
                    {/* Durdur Butonu */}
                    {/* <Button variant="danger" onClick={handleClickStop}>Durdur</Button> */}

                    <Button variant={isPlaying ? "warning" : "info"} onClick={handlePausePlay} disabled={!hasStarted}>
                        {isPlaying ? <HiMiniPause size={30} /> : <HiMiniPlay size={30} />}
                    </Button>


                </div>

                <div style={styles.buttonContainer}>
                    <Button variant="primary" style={{ marginTop: '10px', marginBottom: '50px' }} onClick={gitGunler}>Günler</Button>
                </div>

            </div>

        </div>
    );

};


const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    blinkingText: {
        margin: '10px',
        animation: 'blinking 1s infinite' // Yanıp sönme efektini uygula
    },
    numbersOverlay: {
        position: 'absolute',
        top: 170,
        left: 450,
        right: 450,
        bottom: 224,
        backgroundColor: 'rgba(411, 211, 211, 0.4)',
    },
    
    
};


export default SchulteTablosu;
