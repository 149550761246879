import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Login from './Login';

import Gunler from './Gunler';
import Egzersizler from './Egzersizler';
import GozEgzersizi from './GozEgzersizi';
import RenkOkuma from './RenkOkuma';
import SchulteTablosu from './SchulteTablosu';
import DogruRenkBul from './DogruRenkBul';
import KelimeBul from './KelimeBul';
import TekCift from './TekCift';
import KelimeEgzersizi from './KelimeEgzersizi';
import UcluBlokGorme from './UcluBlokGorme';

import GozEgzersiziMerdiven from './GozEgzersiziMerdiven';
import GozEgzersiziSoldanSaga from './GozEgzersiziSoldanSaga';
import GozEgzersiziKoselerTekrar from './GozEgzersiziKoselerTekrar';
import GozEgzersiziKoseler from './GozEgzersiziKoseler';
import GozEgzersiziDaire from './GozEgzersiziDaire';
import GozEgzersiziDaireZonklama from './GozEgzersiziDaireZonklama';
import SayiOkuma from './SayiOkuma';




// Prod
function App() {
  return (
    <Router>
      <Routes>

        <Route path="/login" element={<Login />} />

        <Route path="/" element={ <ProtectedRoute><Gunler /></ProtectedRoute>} />
        <Route path="/egzersizler" element={ <ProtectedRoute><Egzersizler /></ProtectedRoute>} />
        <Route path="/goz_egzersizi" element={ <ProtectedRoute><GozEgzersizi /></ProtectedRoute>} />
        <Route path="/renk_okuma" element={ <ProtectedRoute><RenkOkuma /></ProtectedRoute>} />
        <Route path="/schulte_tablosu" element={ <ProtectedRoute><SchulteTablosu /></ProtectedRoute>} />
        <Route path="/dogru_renk_bul" element={ <ProtectedRoute><DogruRenkBul /></ProtectedRoute>} />
        <Route path="/kelime_bul" element={ <ProtectedRoute><KelimeBul /></ProtectedRoute>} />
        <Route path="/tek_cift" element={ <ProtectedRoute><TekCift /></ProtectedRoute>} />
        <Route path="/kelime_egzersizi" element={ <ProtectedRoute><KelimeEgzersizi /></ProtectedRoute>} />
        <Route path="/uclu_blok_gorme" element={ <ProtectedRoute><UcluBlokGorme /></ProtectedRoute>} />
        <Route path="/goz_egzersizi_merdiven" element={ <ProtectedRoute><GozEgzersiziMerdiven /></ProtectedRoute>} />
        <Route path="/goz_egzersizi_soldansaga" element={ <ProtectedRoute><GozEgzersiziSoldanSaga /></ProtectedRoute>} />
        <Route path="/goz_egzersizi_koseler_tekrar" element={ <ProtectedRoute><GozEgzersiziKoselerTekrar /></ProtectedRoute>} />
        <Route path="/goz_egzersizi_koseler" element={ <ProtectedRoute><GozEgzersiziKoseler /></ProtectedRoute>} />
        <Route path="/goz_egzersizi_daire" element={ <ProtectedRoute><GozEgzersiziDaire /></ProtectedRoute>} />
        <Route path="/goz_egzersizi_daire_zonklama" element={ <ProtectedRoute><GozEgzersiziDaireZonklama /></ProtectedRoute>} />
        <Route path="/sayi_okuma" element={ <ProtectedRoute><SayiOkuma /></ProtectedRoute>} />
        
      </Routes>
    </Router>
  );
}

export default App; 




/*
// Dev
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Gunler />} />
        <Route path="/egzersizler" element={<Egzersizler />} />
        <Route path="/goz_egzersizi" element={<GozEgzersizi />} />
        <Route path="/renk_okuma" element={<RenkOkuma />} />
        <Route path="/schulte_tablosu" element={<SchulteTablosu />} />
        <Route path="/dogru_renk_bul" element={<DogruRenkBul />} />
        <Route path="/kelime_bul" element={<KelimeBul />} />
        <Route path="/tek_cift" element={<TekCift />} />
        <Route path="/kelime_egzersizi" element={<KelimeEgzersizi />} />
        <Route path="/uclu_blok_gorme" element={<UcluBlokGorme />} />
        <Route path="/goz_egzersizi_merdiven" element={<GozEgzersiziMerdiven />} />
        <Route path="/goz_egzersizi_soldansaga" element={<GozEgzersiziSoldanSaga />} />
        <Route path="/goz_egzersizi_koseler_tekrar" element={<GozEgzersiziKoselerTekrar />} />
        <Route path="/goz_egzersizi_koseler" element={<GozEgzersiziKoseler />} />
        <Route path="/goz_egzersizi_daire" element={<GozEgzersiziDaire />} />
        <Route path="/goz_egzersizi_daire_zonklama" element={<GozEgzersiziDaireZonklama />} />
        <Route path="/sayi_okuma" element={<SayiOkuma />} />
      </Routes>
    </Router>
  );
}

export default App;
*/