import React from 'react';
import { useNavigate } from 'react-router-dom';


function Gunler() {

    const navigate = useNavigate();


    const handleClick = (day) => {
        navigate('/egzersizler', { state: { selectedDay: day } });
        if (day === 9) {
          navigate('/kelime_egzersizi');
        }
    };


    // Her kart için farklı bir gradyan rengi tanımla
    const getCardStyle = (index) => {
      const gradients = [
        'linear-gradient(to left top, #8e24aa, #7b1fa2)', // Açık mor ve orta ton mor
        'linear-gradient(to left top, #7b1fa2, #6a1b9a)', // Orta ton mor ve mor
        'linear-gradient(to left top, #6a1b9a, #5c6bc0)', // Mor ve mavi-mor
        'linear-gradient(to left top, #5c6bc0, #5e35b1)', // Mavi-mor ve koyu mor
        'linear-gradient(to left top, #5e35b1, #512da8)', // Koyu mor ve daha koyu mor
        'linear-gradient(to left top, #512da8, #4527a0)', // Daha koyu mor ve çok koyu mor
        'linear-gradient(to left top, #4527a0, #3949ab)', // Çok koyu mor ve lacivert-mor
        'linear-gradient(to left top, #3949ab, #283593)', // Lacivert-mor ve koyu lacivert
        'linear-gradient(to left top, #283593, #1a237e)', // Koyu lacivert ve gece moru
      ];
    
      return {
        ...styles.card,
        background: gradients[index % gradients.length],
      };
    };
    
    
    

    return (

        <div style={styles.container}>

          <h1 style={{ marginBottom: '30px' }}>Hızlı Okuma Kursu</h1>

          <div style={styles.row}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} style={getCardStyle(index)} onClick={() => handleClick(index + 1)}>
                    <div style={styles.dayText}>{`${index + 1}. Gün`}</div>
                    <div style={styles.numberText}>{index + 1}</div>
                </div>
              ))}
          </div>

          <div style={styles.row}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index + 4} style={getCardStyle(index + 4)} onClick={() => handleClick(index + 5)}>
                    <div style={styles.dayText}>{`${index + 5}. Gün`}</div>
                    <div style={styles.numberText}>{index + 5}</div>
                </div>
              ))}
          </div>

          <div style={styles.row}>
                <div key={9} style={styles.cardKelimeEgzersizi} onClick={() => handleClick(9)}>
                  <span style={styles.textKelimeEgzersizi}>Kelime Egzersizi</span>
                </div>
          </div>


        </div>
    
    );
};


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#fafafa', // Açık gri tonu
    // Diğer alternatifler: '#f0f4f7', // Açık gri tonu, '#f2f2f2' (açık gri), '#fafafa' (çok açık gri), '#eaeaea' (bej)
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20, // Satırlar arası boşluk
  },
  card: {
    width: '170px',
    height: '170px',
    borderRadius: '10px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    cursor: 'pointer',
    marginLeft: 20,
  },
  dayText: {
    color: 'white',
    fontSize: '18px',
  },
  numberText: {
    color: 'white',
    fontSize: '96px',
    alignSelf: 'flex-end',
  },
  cardKelimeEgzersizi: {
    width: '370px',
    height: '100px',
    borderRadius: '10px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    cursor: 'pointer',
    marginLeft: 15,
    background: 'linear-gradient(to left top, #283593, #6a237e)',
  },
  textKelimeEgzersizi: {
    color: 'white',
    fontSize: '38px',
    alignSelf: 'center',
  },
};


export default Gunler;



