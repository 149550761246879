import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import Ball from './ball.png';
import PapatyaBeyaz from './PapatyaBeyaz.png';
import PapatyaMor from './Papatya-MorRenk-Transparan.png';
import KarTanesiMavi from './kartanesi-mavi.png';
import KarTanesiBeyaz from './kartanesi-beyaz.png';
import Yildiz from './yildiz.png';
import Gunes from './gunes.png';


import '../css/GozEgzersiziDaire.css';



const GozEgzersiziDaireZonklama = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const selectedDay = location.state?.selectedDay;
    const icon = location.state?.selectedIcon;
    const selectedAnimationDuration = location.state?.selectedAnimationDuration;

    const [key, setKey] = useState(0);
    const [animationStep, setAnimationStep] = useState(0);

    const STEP_DURATION = selectedAnimationDuration * 1000; // 1sn
    const ANIMATION_STEPS = 30 / selectedAnimationDuration;

    useEffect(() => {
        if (animationStep < ANIMATION_STEPS) {
            const timer = setTimeout(() => {
                setKey(prevKey => (prevKey === 0 ? 1 : 0)); // Key'i değiştirerek animasyonu tetikle
                setAnimationStep(prevStep => prevStep + 1); // Animasyon adımını ilerlet
            }, STEP_DURATION);
            return () => clearTimeout(timer);
        } else {
            navigate('/goz_egzersizi_koseler', { state: { selectedDay: selectedDay, selectedIcon: icon, selectedAnimationDuration: selectedAnimationDuration } });
        }
    }, [animationStep, navigate, selectedDay, icon, selectedAnimationDuration, STEP_DURATION]);

    const circleStyle = {
        width: '320px',
        height: '320px',
        backgroundColor: 'purple',
        borderRadius: '50%',
        animationDuration: `${selectedAnimationDuration}s`,
        animationFillMode: 'forwards',
    };


    const animationClass = key === 0 ? "shrink" : "grow";
    const animationStyles = key === 0 ? { ...circleStyle, animationName: 'shrink' } : { ...circleStyle, animationName: 'grow' };



    const gitGunler = () => {
        navigate('/');
    };

    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <h1 style={{ margin: '20px', textAlign: 'center' }}>Göz Egzersizi - {selectedDay}. Gün</h1>
            <div 
                style={{ 
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    background: '#E6E6FA', 
                    borderRadius: '30px', 
                    width: '940px',
                    height: '480px', 
                }}
            >
               
               <div key={key} className={animationClass} style={animationStyles} />
                
            </div>

            {/* Kontrol Butonları */}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '50px', marginBottom: '50px' }}>
                <Button variant="primary" onClick={gitGunler}>Günler</Button>
            </div>

        </div>
        
    );
    
};

export default GozEgzersiziDaireZonklama;

