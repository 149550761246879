import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { HiMiniPlay } from "react-icons/hi2";
import { HiMiniPause } from "react-icons/hi2";

import Button from 'react-bootstrap/Button';

import '../css/SayiOkuma.css';



const SayiOkuma = () => {

    const navigate = useNavigate();
    const location = useLocation();


    const verilenSure = 120;
    const selectedDay = location.state?.selectedDay;
    const selectedInterval = location.state?.selectedInterval;

    const [firstDigit, setFirstDigit] = useState(0);
    const [secondDigit, setSecondDigit] = useState(0);
    const [thirdDigit, setThirdDigit] = useState(0);    

    const [isPlaying, setIsPlaying] = useState(false); // Zamanlayıcının oynatma durumu için state
    const [key, setKey] = useState(0); // Sayaç süresini sıfırlamak için kullanılacak
    const [isVisible, setIsVisible] = useState(false);
    const [isFlashing, setIsFlashing] = useState(false);

    const [hasStarted, setHasStarted] = useState(false);


    const updateInterval = 3000; // Rakamların güncellenme hızı (milisaniye)



    useEffect(() => {
        let interval;
    
        if (isPlaying) {
            // Zamanlayıcı başlatıldığında, siyah div'i periyodik olarak göster ve gizle
            interval = setInterval(() => {
                // Siyah div'i göster
                setIsVisible(true);
    
                // 500 ms sonra siyah div'i gizle
                setTimeout(() => {
                    setIsVisible(false);
                }, selectedInterval * 1000);
    
                // Rakamları güncelle
                setFirstDigit(Math.floor(Math.random() * 10));
                setSecondDigit(Math.floor(Math.random() * 10));
                setThirdDigit(Math.floor(Math.random() * 10));
            }, updateInterval);
        }
    
        return () => {
            clearInterval(interval);
            // Eğer zamanlayıcı durdurulduysa, siyah div'i hemen gizle
            setIsVisible(false);
        };
    }, [isPlaying, updateInterval]);


    useEffect(() => {
        if (isVisible) {
          setIsFlashing(true);
          setTimeout(() => {
            setIsFlashing(false);
          }, 250); // Yanıp sönme süresi, burada 500 ms
        }
    }, [isVisible]);

    // Yanıp sönme efektini kontrol eden CSS
    const flashStyle = {
        animation: isFlashing ? 'flash 0.1s ease-in-out 5' : 'none',
    };


    const handleClickStart = () => {
        setIsPlaying(true); // Zamanlayıcıyı başlat
        setKey(prevKey => prevKey + 1); // Sayaç süresini sıfırla
        setHasStarted(true);
    };


    /* const handleClickStop = () => {
        setIsPlaying(false); // Zamanlayıcıyı durdur
    }; */

    // Pause/Play tuşuna basıldığında çağrılacak fonksiyon
    const handlePausePlay = () => {
        setIsPlaying(!isPlaying); // Oyunun oynatma durumunu tersine çevir
    };


    const goToDays = () => {
        navigate('/');
    };


    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setIsPlaying(false); // Süre bittiğinde zamanlayıcıyı durdur
            return <h4 style={styles.blinkingText}>Süre Doldu</h4>;
        }
      
        return (
          <div className="timer">
            <div className="text">Kalan Süre</div>
            <div className="value">{Math.floor(remainingTime / 60)}:{String(remainingTime % 60).padStart(2, '0')}</div>
            <div className="text">dakika</div>
          </div>
        );
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

            <h1 style={{ margin: '20px' }}><center>Sayı Okuma - {selectedDay}. Gün</center></h1>


            {/* Lila renkli div */}
            <div style={{ width: '1300px', height: '400px', backgroundColor: '#E6E6FA', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '30px', position: 'relative' }}>
    
                {/* Kırmızı nokta div */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FD7A89', borderRadius: '50%', width: '15px', height: '15px', position: 'absolute', top: '180px', left: '630px' }} />
                
                {/* Siyah renkli div */}
                <div style={{ ...flashStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', padding: '10px', width: '500px', borderRadius: '10px', position: 'absolute', top: '110px', left: '390px', display: isVisible ? 'flex' : 'none' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: '150px', color: 'white', fontSize: '14px' }}>
                        <div>{firstDigit}</div>
                        <div style={{ marginLeft: '130px', marginRight: '130px' }}>{secondDigit}</div>
                        <div>{thirdDigit}</div>
                    </div>
                </div>

            </div>

            {/* Kontrol Butonları ve Timer */}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    
                {/* Başlat Butonu */}
                <div style={{ width: '80px' }}></div>
                <Button variant="success" onClick={handleClickStart}>Başlat</Button>
                <div style={{ width: '50px' }}></div>
                
                {/* Timer Wrapper */}
                <div className="timer-wrapper">
                    <CountdownCircleTimer
                        key={key}
                        isPlaying={isPlaying}
                        duration={verilenSure}
                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                        colorsTime={[verilenSure, verilenSure / 2, verilenSure / 3, 0]}
                        onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                    >
                        {renderTime}
                    </CountdownCircleTimer>
                </div>
                
                {/* Durdur Butonu */}
                <div style={{ width: '50px' }}></div>
                {/* <Button variant="danger" onClick={handleClickStop}>Durdur</Button> */}

                <Button variant={isPlaying ? "warning" : "info"} onClick={handlePausePlay} disabled={!hasStarted}>
                    {isPlaying ? <HiMiniPause size={30} /> : <HiMiniPlay size={30} />}
                </Button>

                <Button variant="primary" style={{ marginLeft: '80px' }} onClick={goToDays}>Günler</Button>

            </div>

        </div>
    );
}


const styles = {
    blinkingText: {
        animation: 'blinking 1s infinite'
    }
};


export default SayiOkuma;

