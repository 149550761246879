import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { HiMiniPlay } from "react-icons/hi2";
import { HiMiniPause } from "react-icons/hi2";

import Button from 'react-bootstrap/Button';

import { kelimeListesi, alfabe } from './KelimeListesi';

import '../css/KelimeBul.css';




const KelimeBul = () => {

    const navigate = useNavigate();
    const location = useLocation();

    
    const verilenSure = 120;
    const selectedDay = location.state?.selectedDay;
    const satirSayisi = location.state?.rowNumbers;
    const sutunSayisi = 28;
    const bilinmesiGerekenKelimeSayisi = location.state?.targetNumber;


    const [alan, setAlan] = useState([]);
    const [bilinmesiGerekenKelimeler, setBilinmesiGerekenKelimeler] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [key, setKey] = useState(0);
    const [toplamBulunan, setToplamBulunan] = useState(0);
    const [bulunanSayilar, setBulunanSayilar] = useState(0);

    const [hasStarted, setHasStarted] = useState(false);



    useEffect(() => {
        alanOlustur();
    }, []);


    const alanOlustur = () => {
        let yeniAlan = [];
        let kullanilanKelimeler = new Set();
    
        // Alanı oluştur
        for (let i = 0; i < satirSayisi; i++) {
            let satir = [];
            let kelimeSayisi = 0;
    
            for (let j = 0; j < sutunSayisi; ) {
                if (kelimeSayisi < 10) {
                    const rastgeleKelime = kelimeListesi[Math.floor(Math.random() * kelimeListesi.length)];
                    if (!kullanilanKelimeler.has(rastgeleKelime)) {
                        kullanilanKelimeler.add(rastgeleKelime);
    
                        rastgeleKelime.split('').forEach(harf => {
                            satir.push({ harf: harf, kelime: rastgeleKelime, renk: 'black' });
                            j++;
                        });
    
                        kelimeSayisi++;
                    }
                } else {
                    break;
                }
            }
            yeniAlan.push(satir);
        }
    
        // Tekrar eden kelimeleri kontrol et ve sil
        let kelimelerArray = Array.from(kullanilanKelimeler);
        let tekrarEdenKelimeleriSil = new Set(kelimelerArray.filter((item, index) => kelimelerArray.indexOf(item) !== index));
        
        tekrarEdenKelimeleriSil.forEach(kelime => {
            kullanilanKelimeler.delete(kelime);
            // Alternatif olarak burada yeni bir kelime ekleyebilirsiniz
        });
    
        // Bilinmesi gereken kelimeleri seç
        let bilinmesiGerekenKelimeler = [];
        while (bilinmesiGerekenKelimeler.length < bilinmesiGerekenKelimeSayisi) {
            const rastgeleKelime = [...kullanilanKelimeler][Math.floor(Math.random() * kullanilanKelimeler.size)];
            if (!bilinmesiGerekenKelimeler.includes(rastgeleKelime)) {
                bilinmesiGerekenKelimeler.push(rastgeleKelime);
            }
        }
    
        // Bilinmesi gereken kelimeleri kırmızı renkle işaretle
        yeniAlan = yeniAlan.map(satir =>
            satir.map(hucre => ({
                ...hucre,
                //renk: bilinmesiGerekenKelimeler.includes(hucre.kelime) ? 'red' : 'black'
                renk: bilinmesiGerekenKelimeler.includes(hucre.kelime) ? 'black' : 'black'
            }))
        );
    
        setBilinmesiGerekenKelimeler(bilinmesiGerekenKelimeler);
        setAlan(yeniAlan);
    };
    
    
    // Kelimelerin konumlarını ve rengini güncelleme işlevi
    const harfSec = (satirIndex, sutunIndex) => {
        const secilenHucre = alan[satirIndex][sutunIndex];
        const kelime = secilenHucre.kelime;
    
        if (isPlaying && bilinmesiGerekenKelimeler.includes(kelime)) {
            let guncellenmisAlan = alan.map(satir =>
                satir.map(hucre => {
                    if (hucre.kelime === kelime) {
                        return { ...hucre, renk: 'green' }; // Kelimenin tüm harflerini yeşile çevir
                    }
                    return hucre;
                })
            );
    
            setAlan(guncellenmisAlan);
            setToplamBulunan(prevToplam => prevToplam + 1);
            setBulunanSayilar(prevToplam => prevToplam + 1);
    
            // Bilinmesi gereken kelimeler listesinden kelimeyi çıkar
            setBilinmesiGerekenKelimeler(kelimeler => {
                const yeniKelimeler = kelimeler.filter(k => k !== kelime);
                // Eğer tüm kelimeler bulunmuşsa, yeni bir set oluştur
                if (yeniKelimeler.length === 0) {
                    setBulunanSayilar(0);
                    alanOlustur(); // Yeni kelime seti ve alan oluştur
                }
                return yeniKelimeler;
            });
        }
    };
    
    


    // Countdown sayacı için render fonksiyonu
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setIsPlaying(false); // Süre bittiğinde oyunu durdur
            return <h4 style={styles.blinkingText}>Süre Doldu</h4>;
        }

        return (
          <div className="timer">
            <div className="text">Kalan Süre</div>
            <div className="value">{Math.floor(remainingTime / 60)}:{String(remainingTime % 60).padStart(2, '0')}</div>
            <div className="text">dakika</div>
          </div>
        );
    };


    const handleClickStart = () => {
        alanOlustur();
        setIsPlaying(true);
        setToplamBulunan(0);
        setBulunanSayilar(0);
        setKey(prevKey => prevKey + 1);
        setHasStarted(true);
    };


    /* const handleClickStop = () => {
        setIsPlaying(false);
    }; */

    // Pause/Play tuşuna basıldığında çağrılacak fonksiyon
    const handlePausePlay = () => {
        setIsPlaying(!isPlaying); // Oyunun oynatma durumunu tersine çevir
    };


    const gitGunler = () => {
        navigate('/');
    };



    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <div>
                <h1 style={{ margin: '20px' }}><center>Kelime Bul - {selectedDay}. Gün</center></h1>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <h3>{bilinmesiGerekenKelimeler.join(", ")}</h3>
                </div>

                <div style={{ 
                        padding: '20px',
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        backgroundColor: '#D3D3D3', 
                        width: '980px', 
                        borderRadius: '30px',
                        height: selectedDay === 1 || selectedDay === 2 ? '350px' : '550px', 
                    }}
                >
                    {alan.map((satir, satirIndex) => (
                        <div 
                            key={satirIndex} 
                            style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                minHeight: '50px',
                                width: '100%',
                            }}
                        >
                            {satir.map((eleman, sutunIndex) => (
                                <span 
                                    key={sutunIndex}
                                    style={{
                                        flex: '1 0 auto', 
                                        textAlign: 'center', 
                                        margin: '0',
                                        minWidth: '25px',
                                        fontSize: '24px',
                                        cursor: isPlaying ? 'pointer' : 'default',
                                        color: eleman.renk || 'black',
                                    }}
                                    onClick={() => harfSec(satirIndex, sutunIndex)} 
                                >
                                    {eleman.harf}
                                </span>
                            ))}
                        </div>
                    ))}
                </div>

                <div style={{ marginTop: '20px', backgroundColor: '#4ad3e7', padding: '10px', borderRadius: '13px', }}>
                    <span><center><strong>Hedef: {bulunanSayilar} / {bilinmesiGerekenKelimeSayisi} - Toplam Bulunan: {toplamBulunan}</strong></center></span>
                </div>

                {/* Kontrol Butonları ve Timer */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    
                    {/* Başlat Butonu */}
                    <Button variant="success" onClick={handleClickStart}>Başlat</Button>
                    
                    {/* Timer Wrapper */}
                    <div className="timer-wrapper">
                        <CountdownCircleTimer
                            key={key}
                            isPlaying={isPlaying}
                            duration={verilenSure}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[verilenSure, verilenSure / 2, verilenSure / 3, 0]}
                            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                    
                    {/* Durdur Butonu */}
                    {/* <Button variant="danger" onClick={handleClickStop}>Durdur</Button> */}

                    <Button variant={isPlaying ? "warning" : "info"} onClick={handlePausePlay} disabled={!hasStarted}>
                        {isPlaying ? <HiMiniPause size={30} /> : <HiMiniPlay size={30} />}
                    </Button>

                </div>

                {/* Günler'e Git Butonu */}
                <div style={styles.buttonContainer}>
                    <Button variant="primary" style={{ marginBottom: '30px' }} onClick={gitGunler}>Günler</Button>
                </div>

            </div>
        </div>
    );

}


// Stil tanımlamaları
const styles = {
    blinkingText: {
        animation: 'blinking 1s infinite'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
};



export default KelimeBul;








