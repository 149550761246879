import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import Ball from './ball.png';
import PapatyaBeyaz from './PapatyaBeyaz.png';
import PapatyaMor from './Papatya-MorRenk-Transparan.png';
import KarTanesiMavi from './kartanesi-mavi.png';
import KarTanesiBeyaz from './kartanesi-beyaz.png';
import Yildiz from './yildiz.png';
import Gunes from './gunes.png';




const GozEgzersiziMerdiven = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const selectedDay = location.state?.selectedDay;
    const icon = location.state?.selectedIcon;
    const selectedAnimationDuration = location.state?.selectedAnimationDuration;

    const [position, setPosition] = useState({ top: '10%', left: '6%' });
    const [step, setStep] = useState(0);

    const STEP_DURATION = selectedAnimationDuration * 1000; // Her adımın süresi
    const ANIMATION_TIMEOUT = 6000; // Toplam animasyon süresi, 11 adım

    useEffect(() => {
        
        const positions = [
            { top: '10%', left: '6%' }, // Başlangıç konumu (sol üst)
            { top: '10%', left: 'calc(100% - 40px)' },  // Sol üstten sağ üste
            { top: 'calc(50% - 25px)', left: '40px' },  // Sağ üstten sol ortaya
            { top: 'calc(50% - 25px)', left: 'calc(100% - 40px)' },  // Sol ortadan sağ ortaya
            { top: 'calc(100% - 40px)', left: '40px' },  // Sağ ortadan sol alta
            { top: 'calc(100% - 40px)', left: 'calc(100% - 40px)' },  // Sol alttan sağ alta
            { top: '10%', left: '40px' },  // Sağ alttan sol üste
            // Tekrar eden hareketler
            { top: '10%', left: 'calc(100% - 40px)' },  // Sol üstten sağ üste
            { top: 'calc(50% - 25px)', left: '40px' },  // Sağ üstten sol ortaya
            { top: 'calc(50% - 25px)', left: 'calc(100% - 40px)' },  // Sol ortadan sağ ortaya
            { top: 'calc(100% - 40px)', left: '40px' },  // Sağ ortadan sol alta
            { top: 'calc(100% - 40px)', left: 'calc(100% - 40px)' },  // Sol alttan sağ alta
        ];

        const changePosition = () => {
            setStep(prevStep => {
                const nextStep = (prevStep + 1) % positions.length;
                setPosition(positions[nextStep]);
                return nextStep;
            });
        };

        const positionInterval = setInterval(changePosition, STEP_DURATION);

        const stopAnimation = setTimeout(() => {
            clearInterval(positionInterval);
            navigate('/goz_egzersizi_soldansaga', { state: { selectedDay: selectedDay, selectedIcon: icon, selectedAnimationDuration: selectedAnimationDuration } });
        }, ANIMATION_TIMEOUT);

        return () => {
            clearTimeout(stopAnimation);
            clearInterval(positionInterval);
        };
    }, []);


    const gitGunler = () => {
        navigate('/');
    };

    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <h1 style={{ margin: '20px', textAlign: 'center' }}>Göz Egzersizi - {selectedDay}. Gün</h1>
            <div 
                style={{ 
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    background: '#E6E6FA', 
                    borderRadius: '30px', 
                    width: '940px',
                    height: '480px', 
                }}
            >
                <div style={{ 
                    position: 'absolute',
                    top: position.top, 
                    left: position.left, 
                    transform: 'translate(-50%, -50%)',
                    width: '70px',
                    height: '70px',
                    backgroundImage: `url(${icon})`,
                    backgroundSize: 'cover',
                    borderRadius: '50%',
                    opacity: 1,
                }} />

            </div>

            {/* Kontrol Butonları */}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '50px', marginBottom: '50px' }}>
                <Button variant="primary" onClick={gitGunler}>Günler</Button>
            </div>

        </div>
        
    );
    
};

export default GozEgzersiziMerdiven;

