import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import Ball from './ball.png';
import PapatyaBeyaz from './PapatyaBeyaz.png';
import PapatyaMor from './Papatya-MorRenk-Transparan.png';
import KarTanesiMavi from './kartanesi-mavi.png';
import KarTanesiBeyaz from './kartanesi-beyaz.png';
import Yildiz from './yildiz.png';
import Gunes from './gunes.png';




const GozEgzersiziSoldanSaga = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const selectedDay = location.state?.selectedDay;
    const icon = location.state?.selectedIcon;
    const selectedAnimationDuration = location.state?.selectedAnimationDuration;

    const [position, setPosition] = useState({ bottom: 'calc(100% - 80px)', left: '70px' });
    const [step, setStep] = useState(0);

    const STEP_DURATION = selectedAnimationDuration * 1000; // Her adımın süresi
    const ANIMATION_TIMEOUT = 11500; // Toplam animasyon süresi

    useEffect(() => {

        const positions = [
            // İlk adımda sol üst köşe
            
            { bottom: 'calc(100% - 80px)', left: '70px' }, // Sol üst + 70px      
            { bottom: '20px', left: '140px' }, // Sol alt + 140px

            { bottom: 'calc(100% - 80px)', left: '140px' }, // Sol üst + 140px
            { bottom: '20px', left: '210px' }, // Sol alt + 210px

            { bottom: 'calc(100% - 80px)', left: '210px' }, // Sol üst + 210px
            { bottom: '20px', left: '280px' }, // Sol alt + 280px

            { bottom: 'calc(100% - 80px)', left: '280px' }, // Sol üst + 280px
            { bottom: '20px', left: '350px' }, // Sol alt + 350px

            { bottom: 'calc(100% - 80px)', left: '350px' }, // Sol üst + 350px
            { bottom: '20px', left: '420px' }, // Sol alt + 420px

            { bottom: 'calc(100% - 80px)', left: '420px' }, // Sol üst + 420px
            { bottom: '20px', left: '490px' }, // Sol alt + 490px

            { bottom: 'calc(100% - 80px)', left: '490px' }, // Sol üst + 490px
            { bottom: '20px', left: '560px' }, // Sol alt + 560px

            { bottom: 'calc(100% - 80px)', left: '560px' }, // Sol üst + 560px
            { bottom: '20px', left: '630px' }, // Sol alt + 630px

            { bottom: 'calc(100% - 80px)', left: '630px' }, // Sol üst + 630px
            { bottom: '20px', left: '700px' }, // Sol alt + 700px

            { bottom: 'calc(100% - 80px)', left: '700px' }, // Sol üst + 700px
            { bottom: '20px', left: '770px' }, // Sol alt + 770px

            { bottom: 'calc(100% - 80px)', left: '770px' }, // Sol üst + 770px
            { bottom: '20px', left: '840px' }, // Sol alt + 840px

            { bottom: 'calc(100% - 80px)', left: '840px' }, // Sol üst + 840px
            
        ];

        const changePosition = () => {
            setStep(prevStep => {
                const nextStep = (prevStep + 1) % positions.length;
                setPosition(positions[nextStep]);
                return nextStep;
            });
        };

        const positionInterval = setInterval(changePosition, STEP_DURATION);

        const stopAnimation = setTimeout(() => {
            clearInterval(positionInterval);
            navigate('/goz_egzersizi_koseler_tekrar', { state: { selectedDay: selectedDay, selectedIcon: icon, selectedAnimationDuration: selectedAnimationDuration } });
        }, ANIMATION_TIMEOUT);

        return () => {
            clearTimeout(stopAnimation);
            clearInterval(positionInterval);
        };
    }, []);

    
    const gitGunler = () => {
        navigate('/');
    };


    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <h1 style={{ margin: '20px', textAlign: 'center' }}>Göz Egzersizi - {selectedDay}. Gün</h1>
            <div 
                style={{ 
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    background: '#E6E6FA', 
                    borderRadius: '30px', 
                    width: '940px',
                    height: '480px', 
                }}
            >

                <div style={{ 
                    position: 'absolute',
                    left: position.left,
                    bottom: position.bottom,
                    width: '70px',
                    height: '70px',
                    backgroundImage: `url(${icon})`,
                    backgroundSize: 'cover',
                    borderRadius: '50%',
                    opacity: 1,
                }} />

            </div>

            {/* Kontrol Butonları */}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '50px', marginBottom: '50px' }}>
                <Button variant="primary" onClick={gitGunler}>Günler</Button>
            </div>

        </div>
        
    );
    
};

export default GozEgzersiziSoldanSaga;

