import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import Ball from './ball.png';
import PapatyaBeyaz from './PapatyaBeyaz.png';
import PapatyaMor from './Papatya-MorRenk-Transparan.png';
import KarTanesiMavi from './kartanesi-mavi.png';
import KarTanesiBeyaz from './kartanesi-beyaz.png';
import Yildiz from './yildiz.png';
import Gunes from './gunes.png';



const GozEgzersiziKoseler = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const selectedDay = location.state?.selectedDay;
    const icon = location.state?.selectedIcon;
    const selectedAnimationDuration = location.state?.selectedAnimationDuration;

    const [position, setPosition] = useState({ top: '20px', left: '20px' });
    const [positionsSequence, setPositionsSequence] = useState([]);

    const STEP_DURATION = selectedAnimationDuration * 1000;
    const TOTAL_STEPS = 15 / selectedAnimationDuration; // Toplam adım sayısı
    const ANIMATION_TIMEOUT = TOTAL_STEPS * STEP_DURATION; // Toplam animasyon süresi

    useEffect(() => {
        const positions = [
            { bottom: '20px', left: '20px' }, // Sol alt köşe
            { top: '20px', right: '20px' },   // Sağ üst köşe
            { bottom: '20px', right: '20px' }, // Sağ alt köşe
            { top: '20px', left: '20px' }     // Sol üst köşe
        ];

        let newPositionsSequence = [];
        let lastPositionIndex = -1;

        for (let i = 0; i < TOTAL_STEPS; i++) {
            let newIndex;
            do {
                newIndex = Math.floor(Math.random() * positions.length);
            } while (newIndex === lastPositionIndex);

            newPositionsSequence.push(positions[newIndex]);
            lastPositionIndex = newIndex;
        }

        setPositionsSequence(newPositionsSequence);

        let step = 0;
        const positionInterval = setInterval(() => {
            if (step < TOTAL_STEPS) {
                setPosition(newPositionsSequence[step]);
                step++;
            }
        }, STEP_DURATION);

        const stopAnimation = setTimeout(() => {
            clearInterval(positionInterval);
            navigate('/goz_egzersizi_merdiven', { state: { selectedDay: selectedDay, selectedIcon: icon, selectedAnimationDuration: selectedAnimationDuration } });
        }, ANIMATION_TIMEOUT);

        return () => {
            clearTimeout(stopAnimation);
            clearInterval(positionInterval);
        };
    }, []); 
    

    const gitGunler = () => {
        navigate('/');
    };


    
    return (

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <h1 style={{ margin: '20px', textAlign: 'center' }}>Göz Egzersizi - {selectedDay}. Gün</h1>
            <div 
                style={{ 
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    background: '#E6E6FA', 
                    borderRadius: '30px', 
                    width: '940px',
                    height: '480px', 
                }}
            >
                <div style={{ 
                    position: 'absolute',
                    top: position.top,
                    bottom: position.bottom,
                    left: position.left,
                    right: position.right,
                    width: '70px',
                    height: '70px',
                    backgroundImage: `url(${icon})`,
                    backgroundSize: 'cover',
                    borderRadius: '50%',
                    opacity: 1,
                }} />

            </div>

            {/* Kontrol Butonları */}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '50px', marginBottom: '50px' }}>
                <Button variant="primary" onClick={gitGunler}>Günler</Button>
            </div>

        </div>
        
    );
    
};

export default GozEgzersiziKoseler;

