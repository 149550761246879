
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { HiMiniPlay } from "react-icons/hi2";
import { HiMiniPause } from "react-icons/hi2";

import Button from 'react-bootstrap/Button';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import '../css/RenkOkuma.css';



const RenkOkuma = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const verilenSure = 120;
    const selectedDay = location.state?.selectedDay;
    const selectedInterval = location.state?.selectedInterval;

    const [currentColorName, setCurrentColorName] = useState('');
    const [textColor, setTextColor] = useState('');
    const [isPlaying, setIsPlaying] = useState(false); // Zamanlayıcının oynatma durumu için state
    const [key, setKey] = useState(0); // Sayaç süresini sıfırlamak için kullanılacak

    const [preparedColorTextPairs, setPreparedColorTextPairs] = useState([]);

    const [hasStarted, setHasStarted] = useState(false);



    const colorNames = ['Kırmızı', 'Yeşil', 'Mavi', 'Sarı',    'Mor',    'Turuncu',  'Pembe',    'Kahverengi', 'Gri',  'Siyah'];
    const colors =     ['Red',     'Green', 'Blue', 'Yellow', 'Purple', '#FF8C00',  '#FF69B4',  'Brown',      'Grey', 'Black'];
    //const colors =     ['Red',     'Green', 'Blue', 'Yellow', 'Purple', 'Orange',  'Pink',  'Brown',      'Grey', 'Black'];


    // Renk ve yazıları önceden hazırlamak için bir fonksiyon
    const prepareColorsAndTexts = () => {
        const itemCount = Math.ceil(verilenSure / selectedInterval); // Gereken eleman sayısı
        const preparedItems = [];
        let lastColorIndex = -1; // Son seçilen renk indeksi
    
        for (let i = 0; i < itemCount; i++) {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * colors.length);
            } while (randomIndex === lastColorIndex); // Aynı renk tekrar gelirse, yeni bir renk seçene kadar döngüyü tekrarla
    
            preparedItems.push({
                colorName: colorNames[randomIndex],
                textColor: colors[(randomIndex + 1) % colors.length]
            });
    
            lastColorIndex = randomIndex; // Son seçilen renk indeksini güncelle
        }
        return preparedItems;
    };
    


    // Renk Değişimi
    useEffect(() => {
        let currentIndex = 0;
        if (isPlaying && preparedColorTextPairs.length > 0) {
            const colorInterval = setInterval(() => {
                const currentItem = preparedColorTextPairs[currentIndex];
                setCurrentColorName(currentItem.colorName);
                setTextColor(currentItem.textColor);
                currentIndex = (currentIndex + 1) % preparedColorTextPairs.length;
            }, selectedInterval * 1000);
            return () => clearInterval(colorInterval);
        }
    }, [isPlaying, preparedColorTextPairs, selectedInterval]);
    
    
    const handleClickStart = () => {
        setPreparedColorTextPairs(prepareColorsAndTexts()); // Önceden hazırlanan renk ve yazıları ayarla
        setIsPlaying(true); // Zamanlayıcıyı başlat
        setKey(prevKey => prevKey + 1); // Sayaç süresini sıfırla
        setHasStarted(true);
    };

    /* const handleClickStop = () => {
        setIsPlaying(false); // Zamanlayıcıyı durdur
    }; */

    // Pause/Play tuşuna basıldığında çağrılacak fonksiyon
    const handlePausePlay = () => {
        setIsPlaying(!isPlaying); // Oyunun oynatma durumunu tersine çevir
    };

    const goToDays = () => {
        navigate('/');
    };

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setIsPlaying(false); // Süre bittiğinde zamanlayıcıyı durdur
            return <h4 style={styles.blinkingText}>Süre Doldu</h4>;
        }
      
        return (
          <div className="timer">
            <div className="text">Kalan Süre</div>
            <div className="value">{Math.floor(remainingTime / 60)}:{String(remainingTime % 60).padStart(2, '0')}</div>
            <div className="text">dakika</div>
          </div>
        );
    };



    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

            <h1 style={{ margin: '20px' }}><center>Renk Okuma - {selectedDay}. Gün</center></h1>

            <div
                style={{
                    width: '850px',
                    height: '350px',
                    backgroundColor: '#E6E6FA',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '30px',
                    color: textColor,
                }}
            >
                <h1>{currentColorName}</h1>
            </div>

            {/* Kontrol Butonları ve Timer */}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    
                {/* Başlat Butonu */}
                <Button variant="success" onClick={handleClickStart}>Başlat</Button>
                <div style={{ width: '50px' }}></div>
                
                {/* Timer Wrapper */}
                <div className="timer-wrapper">
                    <CountdownCircleTimer
                        key={key}
                        isPlaying={isPlaying}
                        duration={verilenSure}
                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                        colorsTime={[verilenSure, verilenSure / 2, verilenSure / 3, 0]}
                        onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                    >
                        {renderTime}
                    </CountdownCircleTimer>
                </div>
                
                {/* Durdur Butonu */}
                <div style={{ width: '50px' }}></div>
                {/* <Button variant="danger" onClick={handleClickStop}>Durdur</Button> */}

                <Button variant={isPlaying ? "warning" : "info"} onClick={handlePausePlay} disabled={!hasStarted}>
                    {isPlaying ? <HiMiniPause size={30} /> : <HiMiniPlay size={30} />}
                </Button>

            </div>

            <Button variant="primary" style={{ marginBottom: '30px' }} onClick={goToDays}>Günler</Button>

        </div>
    );
};


const styles = {
    blinkingText: {
        animation: 'blinking 1s infinite'
    }
};


export default RenkOkuma;


