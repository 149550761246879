import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { HiMiniPlay } from "react-icons/hi2";
import { HiMiniPause } from "react-icons/hi2";

import Button from 'react-bootstrap/Button';

import '../css/DogruRenkBul.css';





const DogruRenkBul = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const selectedDay = location.state?.selectedDay;
    const targetNumber = location.state?.targetNumber;
    const verilenSure = location.state?.duration * 60;


    const [renkler, setRenkler] = useState([]);
    const [bulunanRenkler, setBulunanRenkler] = useState(0);
    const [toplamBulunan, setToplamBulunan] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false); // Zamanlayıcının oynatma durumu için state
    const [key, setKey] = useState(0); // Sayaç süresini sıfırlamak için kullanılacak

    const [hasStarted, setHasStarted] = useState(false);


    const renkIsimleri = ['Kırmızı', 'Yeşil',   'Mavi',    'Sarı',    'Mor',     'Turuncu', 'Pembe',   'Kahverengi', 'Gri',     'Siyah'];
    const renkKodlari =  ['#FF0000', '#008000', '#0000FF', '#FFFF00', '#800080', '#FF8C00', '#FF69B4', '#A52A2A',    '#808080', '#000000'];


    useEffect(() => {
        renkleriYenile();
    }, []);



    const renkleriYenile = () => {
        let yeniRenkler = Array(110).fill(null);
        let dogruRenkIndeksleri = new Set();
    
        // 5 adet doğru renk indeksi oluştur
        while (dogruRenkIndeksleri.size < targetNumber) {
            dogruRenkIndeksleri.add(Math.floor(Math.random() * yeniRenkler.length));
        }
    
        yeniRenkler = yeniRenkler.map((_, index) => {
            const rastgeleRenkIndex = Math.floor(Math.random() * renkIsimleri.length);
            const dogruRenk = dogruRenkIndeksleri.has(index);
            
            // Yanlış renkler için renk kodunu rastgele seçerken doğru renk kodunu hariç tut
            let yanlisRenkKodu;
            do {
                yanlisRenkKodu = renkKodlari[Math.floor(Math.random() * renkKodlari.length)];
            } while (yanlisRenkKodu === renkKodlari[rastgeleRenkIndex]);
    
            return {
                isim: renkIsimleri[rastgeleRenkIndex],
                renk: dogruRenk ? renkKodlari[rastgeleRenkIndex] : yanlisRenkKodu,
                dogru: dogruRenk,
                gorunur: true
            };
        });
    
        setRenkler(yeniRenkler);
    };
    

    const renkSec = (index) => {
        const secilenRenk = renkler[index];
        if (isPlaying && secilenRenk.dogru && secilenRenk.gorunur) {
            let guncellenmisRenkler = [...renkler];
            guncellenmisRenkler[index].gorunur = false; // Renk yazısını kaldır
            setRenkler(guncellenmisRenkler);

            setBulunanRenkler(bulunanRenkler + 1);
            setToplamBulunan(toplamBulunan + 1);
            if (bulunanRenkler + 1 === targetNumber) {
                renkleriYenile();
                setBulunanRenkler(0);
            }
        }
    };


    const handleClickStart = () => {
        renkleriYenile();
        setBulunanRenkler(0);
        setToplamBulunan(0);
        setIsPlaying(true); // Zamanlayıcıyı başlat
        setKey(prevKey => prevKey + 1); // Sayaç süresini sıfırla
        setHasStarted(true);
    };


    /* const handleClickStop = () => {
        setIsPlaying(false); // Zamanlayıcıyı durdur
        //setBulunanRenkler(0);
    }; */


    // Pause/Play tuşuna basıldığında çağrılacak fonksiyon
    const handlePausePlay = () => {
        setIsPlaying(!isPlaying); // Oyunun oynatma durumunu tersine çevir
    };


    const gitGunler = () => {
        navigate('/');
    };


    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setIsPlaying(false); // Süre bittiğinde zamanlayıcıyı durdur
            return <h4 style={styles.blinkingText}>Süre Doldu</h4>;
        }
        
        return (
          <div className="timer">
            <div className="text">Kalan Süre</div>
            <div className="value">{Math.floor(remainingTime / 60)}:{String(remainingTime % 60).padStart(2, '0')}</div>
            <div className="text">dakika</div>
          </div>
        );
    };




    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

            <div>
                <h1 style={{ margin: '20px' }}><center>Doğru Renk Bul - {selectedDay}. Gün</center></h1>
                <div style={{ padding: '10px', position: 'relative', display: 'grid', gridTemplateColumns: `repeat(11, 1fr)`, gap: '1px', backgroundColor: '#d3d3d3', borderRadius: '20px', }}>
                    {renkler.map((renk, index) => (
                        renk.gorunur && (
                            <div 
                                key={index} 
                                style={{ 
                                    margin: '5px',
                                    cursor: isPlaying ? 'pointer' : 'default',
                                    fontSize: '20px',
                                    color: renk.renk,
                                    //background: renk.dogru && renk.gorunur ? '#f5f5f5' : '#d3d3d3',
                                }}
                                onClick={() => renkSec(index)}
                            >
                                {renk.isim}
                            </div>
                        )
                    ))}
                </div>

                <div style={{ marginTop: '20px', backgroundColor: '#4ad3e7', padding: '10px', display: 'flex', justifyContent: 'center', borderRadius: '15px', }}>
                    <span><h6 style={{ color: "black" }}><center>Yönerge: Yukarıdaki kelimelerden, kendi renginde yazılanları bulup işaretleyiniz.</center></h6></span>
                </div>
                <div style={{ marginTop: '10px', backgroundColor: '#add8e6', padding: '5px', display: 'flex', justifyContent: 'center', borderRadius: '15px', }}>
                    <span><h5 style={{ color: "black" }}><center>Hedef: {bulunanRenkler}/{targetNumber} - Toplam Bulunan: {toplamBulunan}</center></h5></span>
                </div>
                

                {/* Kontrol Butonları ve Timer */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    
                    {/* Başlat Butonu */}
                    <Button variant="success" onClick={handleClickStart}>Başlat</Button>
                    
                    {/* Timer Wrapper */}
                    <div className="timer-wrapper">
                        <CountdownCircleTimer
                            key={key}
                            isPlaying={isPlaying}
                            duration={verilenSure}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[verilenSure, verilenSure / 2, verilenSure / 3, 0]}
                            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                    
                    {/* Durdur Butonu */}
                    {/* <Button variant="danger" onClick={handleClickStop}>Durdur</Button> */}

                    {/* Pause/Play Butonu */}
                    {/* <Button variant={isPlaying ? "warning" : "info"} onClick={handlePausePlay}>
                        {isPlaying ? "Pause" : "Play"}
                    </Button> */}

                    <Button variant={isPlaying ? "warning" : "info"} onClick={handlePausePlay} disabled={!hasStarted}>
                        {isPlaying ? <HiMiniPause size={30} /> : <HiMiniPlay size={30} />}
                    </Button>

                    {/* Günler Butonu */}
                    <Button variant="primary" onClick={gitGunler}>Günler</Button>
                </div>

            </div>
            
        </div>
    );
};


const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    blinkingText: {
        margin: '10px',
        animation: 'blinking 1s infinite' // Yanıp sönme efektini uygula
    },
    
    
    
};



export default DogruRenkBul;
