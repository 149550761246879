import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';





const Egzersizler = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const selectedDay = location.state?.selectedDay; 

  const handleClick = (secilen_egzersiz) => {


    const sayiOkumaAyarlari = {
      1: { selectedInterval: 1},
      2: { selectedInterval: 0.9},
      3: { selectedInterval: 0.8},
      4: { selectedInterval: 0.7},
      5: { selectedInterval: 0.8},
      6: { selectedInterval: 0.5},
      7: { selectedInterval: 0.4},
      8: { selectedInterval: 0.3}
    };

    if (secilen_egzersiz === "Sayı Okuma") {
      const günAyarlari = sayiOkumaAyarlari[selectedDay || 1];
      navigate('/sayi_okuma', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }


    const gozEgzersiziAyarlari = {
      1: { selectedAnimationDuration: 1.00 },
      2: { selectedAnimationDuration: 0.90 },
      3: { selectedAnimationDuration: 0.80 },
      4: { selectedAnimationDuration: 0.70 },
      5: { selectedAnimationDuration: 0.60 },
      6: { selectedAnimationDuration: 0.50 },
      7: { selectedAnimationDuration: 0.40 },
      8: { selectedAnimationDuration: 0.30 }
    };

    if (secilen_egzersiz === "Göz Egzersizi") {
      const günAyarlari = gozEgzersiziAyarlari[selectedDay || 1];
      navigate('/goz_egzersizi', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }


    const ucluBlokGormeAyarlari = {
      1: { cizgiGorunmeSuresi: 2 },
      2: { cizgiGorunmeSuresi: 1.8 },
      3: { cizgiGorunmeSuresi: 1.6 },
      4: { cizgiGorunmeSuresi: 1.4 },
      5: { cizgiGorunmeSuresi: 1.2 },
      6: { cizgiGorunmeSuresi: 1 },
      7: { cizgiGorunmeSuresi: 0.8 },
      8: { cizgiGorunmeSuresi: 0.5 }
    };

    if (secilen_egzersiz === "Üçlü Blok Görme") {
      const günAyarlari = ucluBlokGormeAyarlari[selectedDay || 1];
      navigate('/uclu_blok_gorme', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }


    const tekCiftAyarlari = {
      1: { basamakSayisi: 3, targetNumber: 3 },
      2: { basamakSayisi: 3, targetNumber: 3 },
      3: { basamakSayisi: 3, targetNumber: 3 },
      4: { basamakSayisi: 3, targetNumber: 4 },
      5: { basamakSayisi: 4, targetNumber: 5 },
      6: { basamakSayisi: 4, targetNumber: 6 },
      7: { basamakSayisi: 4, targetNumber: 7 },
      8: { basamakSayisi: 4, targetNumber: 8 }
    };

    if (secilen_egzersiz === "Tek mi Çift mi?") {
      // Eğer selectedDay null ise, varsayılan olarak 1. günü kullan
      const günAyarlari = tekCiftAyarlari[selectedDay || 1];
      navigate('/tek_cift', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }


    const kelimeBulAyarlari = {
      1: { rowNumbers: 6, targetNumber: 3 },
      2: { rowNumbers: 6, targetNumber: 5 },
      3: { rowNumbers: 7, targetNumber: 5 },
      4: { rowNumbers: 8, targetNumber: 5 },
      5: { rowNumbers: 8, targetNumber: 6 },
      6: { rowNumbers: 8, targetNumber: 7 },
      7: { rowNumbers: 10, targetNumber: 6 },
      8: { rowNumbers: 10, targetNumber: 7 }
    };

    if (secilen_egzersiz === "Kelime Bul") {
      // Eğer selectedDay null ise, varsayılan olarak 1. günü kullan
      const günAyarlari = kelimeBulAyarlari[selectedDay || 1];
      navigate('/kelime_bul', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }


    const dogruRenkBulAyarlari = {
      1: { duration: 2, targetNumber: 5 },
      2: { duration: 2, targetNumber: 5 },
      3: { duration: 2, targetNumber: 5 },
      4: { duration: 1.5, targetNumber: 5 },
      5: { duration: 1.5, targetNumber: 5 },
      6: { duration: 1.5, targetNumber: 8 },
      7: { duration: 1.5, targetNumber: 8 },
      8: { duration: 1, targetNumber: 8 }
    };

    if (secilen_egzersiz === "Doğru Renk Bulma") {
      // Eğer selectedDay null ise, varsayılan olarak 1. günü kullan
      const günAyarlari = dogruRenkBulAyarlari[selectedDay || 1];
      navigate('/dogru_renk_bul', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }


    /* const renkOkumaAyarlari = {
      1: { selectedInterval: 1},
      2: { selectedInterval: 0.9},
      3: { selectedInterval: 0.8},
      4: { selectedInterval: 0.7},
      5: { selectedInterval: 0.8},
      6: { selectedInterval: 0.5},
      7: { selectedInterval: 0.4},
      8: { selectedInterval: 0.3}
    }; */

    const renkOkumaAyarlari = {
      1: { selectedInterval: 2},
      2: { selectedInterval: 1.8},
      3: { selectedInterval: 1.6},
      4: { selectedInterval: 1.4},
      5: { selectedInterval: 1.2},
      6: { selectedInterval: 1.0},
      7: { selectedInterval: 0.8},
      8: { selectedInterval: 0.6}
    };

    if (secilen_egzersiz === "Renk Okuma") {
      // Eğer selectedDay null ise, varsayılan olarak 1. günü kullan
      const günAyarlari = renkOkumaAyarlari[selectedDay || 1];
      navigate('/renk_okuma', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }


    const schulteTablosuAyarlari = {
      1: { basamakSayisi: 3, bulunacakSayiAdedi: 5, rowsNumber: 10, columnsNumber: 13 },
      2: { basamakSayisi: 3, bulunacakSayiAdedi: 5, rowsNumber: 10, columnsNumber: 13 },
      3: { basamakSayisi: 3, bulunacakSayiAdedi: 7, rowsNumber: 10, columnsNumber: 13 },
      4: { basamakSayisi: 3, bulunacakSayiAdedi: 7, rowsNumber: 10, columnsNumber: 13 },
      5: { basamakSayisi: 4, bulunacakSayiAdedi: 5, rowsNumber: 10, columnsNumber: 10 },
      6: { basamakSayisi: 4, bulunacakSayiAdedi: 5, rowsNumber: 10, columnsNumber: 10 },
      7: { basamakSayisi: 4, bulunacakSayiAdedi: 7, rowsNumber: 10, columnsNumber: 10 },
      8: { basamakSayisi: 4, bulunacakSayiAdedi: 8, rowsNumber: 10, columnsNumber: 10 }
    };
    
    if (secilen_egzersiz === "Schulte Tablosu") {
        // Eğer selectedDay null ise, varsayılan olarak 1. günü kullan
        const günAyarlari = schulteTablosuAyarlari[selectedDay || 1];
        navigate('/schulte_tablosu', { state: { selectedDay: selectedDay || 1, ...günAyarlari } });
    }
  


  };

  const goBack = () => {
    navigate(-1); // Bir önceki sayfaya geri dön
  };


  const egzersizler = ["Göz Egzersizi", "Üçlü Blok Görme", "Doğru Renk Bulma", "Renk Okuma", "Schulte Tablosu", "Kelime Bul", "Tek mi Çift mi?", "Sayı Okuma"];


  const getCardStyle = (index) => {
    const gradients = [
        'linear-gradient(to left top, #9575cd, #7e57c2)', // Açık mor ve orta ton mor
        'linear-gradient(to left top, #7e57c2, #673ab7)', // Orta ton mor ve mor
        'linear-gradient(to left top, #673ab7, #5e35b1)', // Mor ve koyu mor
        'linear-gradient(to left top, #5e35b1, #512da8)', // Koyu mor ve daha koyu mor
        'linear-gradient(to left top, #512da8, #4527a0)', // Daha koyu mor ve çok koyu mor
        'linear-gradient(to left top, #4527a0, #311b92)', // Çok koyu mor ve en koyu mor
        'linear-gradient(to left top, #311b92, #1a237e)', // En koyu mor ve gece moru
        'linear-gradient(to left top, #1a237e, #000051)', // Gece moru ve neredeyse siyah mor
        'linear-gradient(to left top, #000051, #000000)', // Neredeyse siyah mor ve siyah
    ];

    return {
        ...styles.card,
        background: gradients[index % gradients.length],
    };
  };

  
  

  

  return (
    <div style={styles.mainContainer}>

      <h1 style={{ marginBottom: '20px' }} >{selectedDay}. Gün</h1>

      <div style={styles.cardContainer}>
        {egzersizler.map((egzersiz, index) => (
          <div key={index} style={getCardStyle(index)} onClick={() => handleClick(egzersiz)}>
            <div style={styles.egzersizText}>
              {egzersiz}
            </div>
          </div>
        ))}
      </div>

      <div style={styles.buttonContainer}>
        <Button variant="primary" style={styles.buttonBack} onClick={goBack}>Günler'e Git</Button>
      </div>

    </div>
  );

};


const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    paddingBottom: '20px', // Kartlar ile buton arasında boşluk bırakır
  },
  card: {
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    cursor: 'pointer',

    position: 'relative',  // checkIcon için eklendi

  },
  egzersizText: {
    color: 'white',
    fontSize: '16px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonBack: {
    margin: '20px',
    padding: '10px',
  },

  checkIcon: {
    position: 'absolute', // Simgeyi kart içinde serbestçe hareket ettirmek için
    bottom: '10px', // Alt kenardan 10px yukarıda
    right: '10px', // Sağ kenardan 10px içerde
    color: 'yellow', // Simge rengi mavi
    fontSize: '20px', // Simge boyutu
  },

};

export default Egzersizler;

