import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import { HiPlayCircle } from "react-icons/hi2";
import { HiPauseCircle } from "react-icons/hi2";

import Slider from '@mui/material/Slider';

import '../css/KelimeEgzersizi.css';



import { kelimelerBolum1, kelimelerBolum2, kelimelerBolum3, kelimelerBolum4, kelimelerBolum5, kelimelerBolum6, kelimelerBolum7, kelimelerBolum8, kelimelerBolum9 } from './KelimeListesi';


// Debounce fonksiyonunu tanımlayın
/* function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
      clearTimeout(timeoutID);
      const args = arguments;
      const that = this;
      timeoutID = setTimeout(() => {
        fn.apply(that, args);
      }, delay);
    };
} */
 



const KelimeEgzersizi = () => {

    const navigate = useNavigate();

    const verilenSure = 60;

    const [kelimeBoyutu, setKelimeBoyutu] = useState(50); // px cinsinden
    const [wordsPerMinute, setWordsPerMinute] = useState(120); // Dakikada kaç kelime gösterilecek
    const [gosterilenKelimeAdeti, setGosterilenKelimeAdeti] = useState(1);
    const [secilenBolum, setSecilenBolum] = useState(kelimelerBolum1);
    const [currentSection, setCurrentSection] = useState('Bölüm 1');
    
    const [displayWords, setDisplayWords] = useState([]);

    const [gosterilenKelimeler, setGosterilenKelimeler] = useState([]);
    const [gosterilenKelimeIndex, setGosterilenKelimeIndex] = useState(0);

    const [displayInterval, setDisplayInterval] = useState(500);
    const [activeWord, setActiveWord] = useState('');
    const [activeWords, setActiveWords] = useState([]);

    const [wordIntervalId, setWordIntervalId] = useState(null);



    const [time, setTime] = useState(0);
    const [showWordTime, setShowWordTime] = useState(0);

    const [kelimelerZamani, setKelimelerZamani] = useState({}); // Saniye bazında kelimeleri saklamak için

    const [isPlaying, setIsPlaying] = useState(false); // Zamanlayıcının oynatma durumu için state
    const [key, setKey] = useState(0); // Sayaç süresini sıfırlamak için kullanılacak
    const [remainingTime, setRemainingTime] = useState(verilenSure); // Kalan süreyi saklamak için yeni bir state

    const [customWordsEnabled, setCustomWordsEnabled] = useState(false);
    const [customWords, setCustomWords] = useState(() => {
        // Local storage'dan özel kelimeleri yükle
        const savedWords = localStorage.getItem('customWords');
        return savedWords ? JSON.parse(savedWords) : [];
    });



    // Özel kelimeleri güncelleme ve localStorage'a kaydetme fonksiyonu
    const handleCustomWordsChange = (event) => {
        const inputWords = event.target.value;
        // Noktalama işaretlerini kaldır
        const cleanedWords = inputWords.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
        // Birden fazla boşluğu tek boşluğa çevir
        const finalWords = cleanedWords.replace(/\s{2,}/g," ");
        const wordsArray = finalWords.split(/\s+/); // Boşluklara göre böl
        setCustomWords(wordsArray);
        localStorage.setItem('customWords', JSON.stringify(wordsArray));
    };


    const selectWords = () => {
        let selectedWords = [];
        //console.log('selectedWords.length: ', selectedWords.length);
        //console.log('wordsPerMinute: ', wordsPerMinute);
        while (selectedWords.length <= wordsPerMinute) {
            if (customWordsEnabled && customWords.length > 0) {
                const randomWord = customWords[Math.floor(Math.random() * customWords.length)];
                selectedWords.push(randomWord);
            } else {
                const randomWord = secilenBolum[Math.floor(Math.random() * secilenBolum.length)];
                selectedWords.push(randomWord);
            }
        }
        setDisplayWords(selectedWords);
    };
    

    useEffect(() => {
        let interval = null;
        //console.log("time: ", time);
        if (isPlaying && time < 60) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        } else if (!isPlaying && time !== 0) {
            clearInterval(interval);
        }
        if (time >= verilenSure) {
            setIsPlaying(false); // Süre bittiğinde oyunu durdur
            console.log('gosterilenKelimeler: ', gosterilenKelimeler);
            //setWasStarted(false);
        }

        return () => clearInterval(interval);
    }, [isPlaying, time, wordsPerMinute]);




    useEffect(() => {
        //setDisplayInterval(60000 / wordsPerMinute);
        selectWords();
        //console.log('wordsPerMinute: ', wordsPerMinute);
        //console.log('displayInterval: ', displayInterval);
    }, [wordsPerMinute]);

    useEffect(() => {
        //console.log('*** displayInterval: ', displayInterval);
        setDisplayInterval(60000 / wordsPerMinute);
    }, [displayInterval]);

    useEffect(() => {
        if (isPlaying) {
            const arrayCopy = [...gosterilenKelimeler, activeWords]
            setGosterilenKelimeler(arrayCopy);
            //console.log('gosterilenKelimeler: ', gosterilenKelimeler);
        }
    }, [activeWords]);

    useEffect(() => {
        //console.log('displayWords.length: ', displayWords.length);
        //console.log('displayWords: ', displayWords);
    }, [displayWords]);




    const startWordInterval = (newInterval) => {
        stopWordInterval(); // Mevcut interval varsa durdur
        //console.log('in startWordInterval - newInterval: ', newInterval);
        let wordIndex = 0;
        const id = setInterval(() => {
            // ... Burada interval içinde yapılacak işlemler
            const activeWords = [];
            for (let i = 0; i < gosterilenKelimeAdeti; i++) {
                const currentIndex = (wordIndex + i) % displayWords.length;
                //words = displayWords[currentIndex] + ' ' + words;
                activeWords.push(displayWords[currentIndex]);
            }
            setActiveWords(activeWords);
            //setActiveWord(words);
            //words = '';
            //setActiveWord(displayWords[wordIndex]);
            wordIndex = (wordIndex + gosterilenKelimeAdeti) % displayWords.length;
            //console.log('**** displayInterval ****');
            setGosterilenKelimeIndex((index) => index + 1);
        }, newInterval);
        setWordIntervalId(id); // Yeni interval ID'sini kaydet
    };
    
    const stopWordInterval = () => {
        if (wordIntervalId) {
            clearInterval(wordIntervalId);
            setWordIntervalId(null);
        }
    };
    
    useEffect(() => {
        if (isPlaying) {
            const newInterval = 60000 / wordsPerMinute;
            startWordInterval(newInterval);
        } else {
            stopWordInterval();
        }
    }, [isPlaying]);
    
    useEffect(() => {
        // Kelime gösterimi hızını güncelle, ancak interval'ı yeniden başlatma
        // Bu, onChangeCommitted event'ında yapılacak
    }, [wordsPerMinute]);

    useEffect(() => {
        const initialInterval = 60000 / wordsPerMinute;
        setDisplayInterval(initialInterval);
    }, []); // Bu useEffect sadece bileşen yüklendiğinde çalışır.




    /* useEffect(() => {
        let wordIndex = 0;
        let wordInterval;
        let words = '';

        if (isPlaying) {
            //console.log('displayWords: ', displayWords);
            wordInterval = setInterval(() => {
                const activeWords = [];
                for (let i = 0; i < gosterilenKelimeAdeti; i++) {
                    const currentIndex = (wordIndex + i) % displayWords.length;
                    //words = displayWords[currentIndex] + ' ' + words;
                    activeWords.push(displayWords[currentIndex]);
                }
                setActiveWords(activeWords);
                //setActiveWord(words);
                //words = '';
                //setActiveWord(displayWords[wordIndex]);
                wordIndex = (wordIndex + gosterilenKelimeAdeti) % displayWords.length;
                console.log('**** displayInterval ****');
                setGosterilenKelimeIndex((index) => index + 1);
            }, displayInterval);
        }
    
        return () => clearInterval(wordInterval);
    }, [isPlaying, displayInterval, displayWords]); */


    /* const debounceSetWordsPerMinute = debounce((value) => {
        setWordsPerMinute(value);
    }, 10); */




    useEffect(() => {
        if (isPlaying && !customWordsEnabled) {
          // Oyun başladığında ve özel kelimeler kullanılmıyorsa, seçili bölümü güncelle
          setSecilenBolum(
            currentSection === 'Bölüm 1' ? kelimelerBolum1 : 
            currentSection === 'Bölüm 2' ? kelimelerBolum2 : 
            currentSection === 'Bölüm 3' ? kelimelerBolum3 : 
            currentSection === 'Bölüm 4' ? kelimelerBolum4 : 
            currentSection === 'Bölüm 5' ? kelimelerBolum5 : 
            currentSection === 'Bölüm 6' ? kelimelerBolum6 : 
            currentSection === 'Bölüm 7' ? kelimelerBolum7 : 
            currentSection === 'Bölüm 8' ? kelimelerBolum8 : 
            kelimelerBolum9
          );
        }
    }, [isPlaying, customWordsEnabled, currentSection]);



    const handleBolumDegisikligi = (event) => {
        const yeniBolum = event.target.value;
        setCurrentSection(yeniBolum); // Yeni bölümü state'de sakla
        if (!customWordsEnabled) {
            // Eğer özel kelimeler kullanılmıyorsa, seçili bölümü güncelle
            setSecilenBolum(
                yeniBolum === 'Bölüm 1' ? kelimelerBolum1 : 
                yeniBolum === 'Bölüm 2' ? kelimelerBolum2 : 
                yeniBolum === 'Bölüm 3' ? kelimelerBolum3 : 
                yeniBolum === 'Bölüm 4' ? kelimelerBolum4 : 
                yeniBolum === 'Bölüm 5' ? kelimelerBolum5 : 
                yeniBolum === 'Bölüm 6' ? kelimelerBolum6 : 
                yeniBolum === 'Bölüm 7' ? kelimelerBolum7 : 
                yeniBolum === 'Bölüm 8' ? kelimelerBolum8 : 
                kelimelerBolum9
            );
        }
    };


    const handleCheckboxChange = (e) => {
        setCustomWordsEnabled(e.target.checked);
        if (!e.target.checked) {
          // Checkbox kaldırıldığında ve özel kelimeler kullanılıyorsa, şu anki seçili bölüme dön
          handleBolumDegisikligi({ target: { value: currentSection } });
        }
    };


    const handleClickStart = () => {
        setActiveWord('');
        setTime(0);
        setShowWordTime(0);
        setGosterilenKelimeler([]);
        setGosterilenKelimeIndex(0);
        selectWords();
        //setWasStarted(true);
        // Eğer özel kelimeler kullanılmıyorsa ve bir bölüm seçiliyse, o bölümü kullan
        if (!customWordsEnabled) {
            handleBolumDegisikligi({ target: { value: currentSection } });
        }
        // Eğer süre durmuş ve bitmişse (remainingTime 0 ise), süreyi sıfırla ve başlat
        if (!isPlaying && remainingTime === 0) {
            setKey(prevKey => prevKey + 1); // Sayaç süresini sıfırlamak için key'i güncelle
            setRemainingTime(verilenSure); // Süreyi verilenSure değerine sıfırla
            setIsPlaying(true);
        } else if (!isPlaying) {
            // Eğer oyun zaten durmuş ve süre bitmemişse, kaldığı yerden devam et
            setIsPlaying(true);
        }
        // Eğer oyun zaten başlamışsa (isPlaying true ise), hiçbir şey yapma
    };
    

    const toggleRunning = () => {
        // Eğer özel kelimeler kullanılmıyorsa ve bir bölüm seçiliyse, o bölümü kullan
        if (!customWordsEnabled) {
            handleBolumDegisikligi({ target: { value: currentSection } });
        }
        // Eğer süre durmuş ve bitmişse (remainingTime 0 ise), süreyi sıfırla ve başlat
        if (!isPlaying && remainingTime === 0) {
            setKey(prevKey => prevKey + 1); // Sayaç süresini sıfırlamak için key'i güncelle
            setRemainingTime(verilenSure); // Süreyi verilenSure değerine sıfırla
        }
        setIsPlaying(!isPlaying);
    };


    const handleSliderChange_gosterilenKelimeIndex = (event, newValue) => {
        //console.log('event.target.value: ', newValue);
        setGosterilenKelimeIndex(newValue);
        if (gosterilenKelimeler[newValue]) {
            setActiveWords(gosterilenKelimeler[newValue]);
        }
    };


    const handleSliderChange_wordsPerMinute = (event, newValue) => {
        //console.log('newValue: ', newValue);
        setWordsPerMinute(newValue);
        //setDisplayInterval(60000 / wordsPerMinute);
    };


    const handleSliderChangeCommitted_wordsPerMinute = (event, newValue) => {
        setWordsPerMinute(newValue);
        const newInterval = 60000 / newValue;
        setDisplayInterval(newInterval);
        startWordInterval(newInterval);
    };

    

    const gitGunler = () => {
        navigate('/');
    };

      



    return (

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

            <div style={{ padding: '30px', backgroundColor: '#f8f8f8', borderRadius: '30px', }}>

                <h1 style={{ textAlign: 'center' }}>Kelime Egzersizi</h1>

                <div style={{ marginTop: '20px' }}>
                    <label>
                        <span>Kendi kelimelerini yazmak ister misin? : </span>
                        <input
                            type="checkbox"
                            checked={customWordsEnabled}
                            onChange={handleCheckboxChange}
                        />
                    </label>

                    {customWordsEnabled && (
                        <textarea
                            placeholder="Kendi kelimelerinizi aralarında boşluk bırakarak buraya yazınız..."
                            value={customWords.join(' ')}
                            onChange={handleCustomWordsChange}
                            style={{ marginTop: '10px', width: '100%', height: '100px' }}
                        />
                    )}
                </div>

                <div style={{ marginTop: '40px', marginBottom: '30px' }}>
                    <label>
                        <span>Hangi Kelime Bölümünden Başlamak İstersiniz?: </span>
                        <select onChange={handleBolumDegisikligi}>
                            <option>Bölüm 1</option>
                            <option>Bölüm 2</option>
                            <option>Bölüm 3</option>
                            <option>Bölüm 4</option>
                            <option>Bölüm 5</option>
                            <option>Bölüm 6</option>
                            <option>Bölüm 7</option>
                            <option>Bölüm 8</option>
                            <option>Bölüm 9</option>
                        </select>
                    </label>
                </div>

                <button className="button" onClick={handleClickStart}>
                    <span>Başlat</span>
                </button>

                <button className="button" onClick={toggleRunning} style={{ marginLeft: '30px', }}>
                    {isPlaying ? <HiPauseCircle size={40} /> : <HiPlayCircle size={40} />}
                </button>

                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <span>Geçen Süre (sn): <strong>{time}</strong></span>
                    <Slider
                        min={0} 
                        max={displayWords.length-1} 
                        value={gosterilenKelimeIndex} 
                        onChange={handleSliderChange_gosterilenKelimeIndex}
                        sx={sliderStyle}
                    />
                </div>


                {(
                    <div style={{ 
                        marginTop: '20px',
                        display: 'grid',
                        gridTemplateColumns: `repeat(${gosterilenKelimeAdeti}, auto)`,
                        justifyContent: 'center',
                        gap: '30px',
                        backgroundColor: '#E6E6FA',
                        borderRadius: '30px',
                        width: 'fit-content',
                        padding: '10px',
                        width: '900px',
                        height: '300px',
                    }}>
                        {activeWords.map((kelime, index) => (
                            <div
                                key={index}
                                style={{ 
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: `${kelimeBoyutu}px`,
                                    color: 'black',
                                }}
                            >
                                {kelime}
                            </div>
                        ))}
                    </div>
                )}

                {/*
                { 
                    gosterilenKelimeler.map((wordArray, index) => (
                        <span key={index}>
                            {wordArray[0]}{index < gosterilenKelimeler.length - 1 ? ', ' : ''}
                        </span>
                    ))
                }
                */}

                {/* Sliderlar */}
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <span>Dakikada Kaç Kelime Okumak İstersiniz? : <strong>{wordsPerMinute} kelime/dk.</strong></span>
                    <Slider
                        min={15} 
                        max={600} 
                        value={wordsPerMinute} 
                        //onChange={(e) => debounceSetWordsPerMinute(Number(e.target.value))}
                        onChange={handleSliderChange_wordsPerMinute}
                        onChangeCommitted={handleSliderChangeCommitted_wordsPerMinute}
                        sx={sliderStyle}
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <span>Aynı Anda Gösterilecek Kelime Adeti Kaç Olsun? : <strong>{gosterilenKelimeAdeti} adet</strong></span>
                    <Slider 
                        min={1} 
                        max={5} 
                        value={gosterilenKelimeAdeti} 
                        onChange={(e) => setGosterilenKelimeAdeti(Number(e.target.value))}
                        sx={sliderStyle}
                        disabled={isPlaying}
                    />
                </div>

                <div style={{ marginBottom: '30px' }}>
                    <span>Kelimelerin Ekrandaki Boyutu Ne Olsun? : <strong>{kelimeBoyutu} px</strong></span>
                    <Slider 
                        min={20} 
                        max={150} 
                        value={kelimeBoyutu} 
                        onChange={(e) => setKelimeBoyutu(Number(e.target.value))}
                        sx={sliderStyle}
                    />
                </div>
                
                {/* Kontrol Butonları ve Timer */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="primary" style={{ marginLeft: '60px', marginTop: '30px', marginBottom: '30px', textAlign: 'center' }} onClick={gitGunler}>Günler</Button>
                </div>

            </div>

        </div>
        
    );
};


const sliderStyle = {
    color: '#E6E6FA',
    '& .MuiSlider-thumb': { backgroundColor: '#E6E6FA' },
    '& .MuiSlider-track': { backgroundColor: '#E6E6FA' },
    '& .MuiSlider-rail': { opacity: 0.5, backgroundColor: '#E6E6FA' }
};


export default KelimeEgzersizi;





