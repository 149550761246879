
import { jwtDecode } from 'jwt-decode';


const useAuth = () => {
  
    const token = localStorage.getItem('token');

    //console.log(token);

    if (!token) {
        return false;
    }

    try {
        const { exp } = jwtDecode(token);

        if (Date.now() >= exp * 1000) {
            // Token süresi doldu
            localStorage.removeItem('token'); // Token'ı temizle
            return false;
        }

        // Token geçerli
        return true;
    } catch (error) {
        // Token decode edilemedi, muhtemelen geçersiz
        localStorage.removeItem('token'); // Token'ı temizle
        return false;
    }

};

export default useAuth;
