import React, { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import Button from 'react-bootstrap/Button';

import Ball from './ball.png';
import PapatyaBeyaz from './PapatyaBeyaz.png';
import PapatyaMor from './Papatya-MorRenk-Transparan.png';
import KarTanesiMavi from './kartanesi-mavi.png';
import KarTanesiBeyaz from './kartanesi-beyaz.png';
import Yildiz from './yildiz.png';
import Gunes from './gunes.png';


import '../css/GozEgzersizi.css';


const IconButton = ({ src, label, onClick }) => (
  <button
    onClick={() => onClick(src)}
    style={{
      margin: '10px',
      border: 'none',
      background: 'none',
      cursor: 'pointer'
    }}
  >
    <img src={src} alt={label} style={{ width: '30px', height: '30px' }} />
  </button>
);


const GozEgzersizi = () => {

    const navigate = useNavigate();
    const location = useLocation();
    
    const selectedDay = location.state?.selectedDay;
    const selectedAnimationDuration = location.state?.selectedAnimationDuration;

    const [timeLeft, setTimeLeft] = useState(0); 
    const [animationDuration, setAnimationDuration] = useState(0);
    const [currentAnimation, setCurrentAnimation] = useState('horizontal'); // Mevcut animasyon durumunu tutar

    const [isPlaying, setIsPlaying] = useState(false);
    const [key, setKey] = useState(0);

    const [icon, setIcon] = useState(Ball);



    

    // setIcon fonksiyonunu handleIconClick içinde kullanın
    const handleIconClick = useCallback((src) => {
      setIcon(src);
    }, []);
    
    // Top animasyonları için useEffect
    useEffect(() => {
      const timer = timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);

      if (timeLeft === 1) {
        switch (currentAnimation) {
          case 'horizontal':
            setCurrentAnimation('vertical');
            setTimeLeft(15);
            break;
          case 'vertical':
            setCurrentAnimation('diagonal1');
            setTimeLeft(15);
            break;
          case 'diagonal1':
            setCurrentAnimation('diagonal2');
            setTimeLeft(15);
            break;
          case 'diagonal2':
            setAnimationDuration(0);
            break;
          default:
            break;
        }
      }

      if (currentAnimation === 'diagonal2' && timeLeft === 1) {
        setAnimationDuration(0);
        navigate('/goz_egzersizi_daire', { state: { selectedDay: selectedDay, selectedIcon: icon, selectedAnimationDuration: selectedAnimationDuration } });
      }

      return () => clearInterval(timer);
    }, [timeLeft, currentAnimation]);


  
    const handleClickStart = () => {
      setTimeLeft(15);
      setAnimationDuration(selectedAnimationDuration);
      setIsPlaying(true);
    };


    const gitGunler = () => {
      navigate('/');
    };

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setIsPlaying(false); // Süre bittiğinde oyunu durdur
            return <h4 style={styles.blinkingText}>Süre Doldu</h4>;
        }

        return (
          <div className="timer">
            <div className="text">Kalan Süre</div>
            <div className="value">{Math.floor(remainingTime / 30)}:{String(remainingTime % 30).padStart(2, '0')}</div>
            <div className="text">dakika</div>
          </div>
        );
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

            <h1 style={{ margin: '20px', textAlign: 'center' }}>Göz Egzersizi - {selectedDay}. Gün</h1>

            {/* İkon seçim butonları */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
              <IconButton src={Ball} label="Ball" onClick={handleIconClick} />
              <IconButton src={PapatyaBeyaz} label="Papatya Beyaz" onClick={handleIconClick} />
              <IconButton src={PapatyaMor} label="Papatya Mor" onClick={handleIconClick} />
              <IconButton src={KarTanesiMavi} label="Kar Tanesi Mavi" onClick={handleIconClick} />
              <IconButton src={KarTanesiBeyaz} label="Kar Tanesi Beyaz" onClick={handleIconClick} />
              <IconButton src={Yildiz} label="Yıldız" onClick={handleIconClick} />
              <IconButton src={Gunes} label="Güneş" onClick={handleIconClick} />
            </div>

            <div style={styles.animationArea}>

              <img
                  src={icon}
                  alt=""
                  style={{
                    ...(styles.icon),
                    animationDuration: `${animationDuration}s`,
                    animationName: currentAnimation === 'horizontal' ? 'moveHorizontal' :
                                    currentAnimation === 'vertical'   ? 'moveVertical'   :
                                    currentAnimation === 'diagonal1'  ? 'moveDiagonal1'  : 'moveDiagonal2'
                  }}
                />

            </div>

          {/* Kontrol Butonları ve Timer */}
          <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '50px', marginBottom: '50px' }}>
              <Button variant="success" onClick={handleClickStart}>Başlat</Button>
              <div style={{ marginRight: '80px'}}></div>
              <Button variant="primary" onClick={gitGunler}>Günler</Button>
          </div>
          
        </div>
      
    );
};


const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
  },
  title: {
    marginBottom: '20px',
  },
  animationArea: {
    width: '940px',
    height: '480px',
    backgroundColor: '#E6E6FA',
    position: 'relative',
    borderRadius: '30px', 
  },
  icon: {
    width: '70px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: 'move ease-in-out infinite alternate',
  },
  slider: {
    marginTop: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonEgzersizler: {
    margin: '20px',
    padding: '10px',
  },
  timerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  timer: {
    fontSize: '20px',
  },

};

export default GozEgzersizi;
