import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { HiMiniPlay } from "react-icons/hi2";
import { HiMiniPause } from "react-icons/hi2";

import Button from 'react-bootstrap/Button';

import '../css/TekCift.css';



const TekCift = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const verilenSure = 120;
    const selectedDay = location.state?.selectedDay;
    const bilinmesiGerekenSayiAdeti = location.state?.targetNumber;
    const basamakSayisi = location.state?.basamakSayisi;

    const rows = 8;
    const columns = 10;

    const [borderR, setBorderR] = useState('25px');
    
    const [numbers, setNumbers] = useState([]);
    const [targetNumbers, setTargetNumbers] = useState([]);
    const [currentNumberIndex, setCurrentNumberIndex] = useState(0);
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [secim, setSecim] = useState('');
    const [isPlaying, setIsPlaying] = useState(false); // Zamanlayıcının oynatma durumu için state
    const [key, setKey] = useState(0); // Sayaç süresini sıfırlamak için kullanılacak
    const [toplamBulunan, setToplamBulunan] = useState(0);
    const [bulunanSayilar, setBulunanSayilar] = useState(0);

    const [hasStarted, setHasStarted] = useState(false);



    useEffect(() => {
        setSecim(Math.random() < 0.5 ? 'TEK' : 'ÇİFT');
    }, []);

    useEffect(() => {
        //console.log("Seçim: ", secim);
        generateRandomNumbers();     
    }, [secim]); // Bu useEffect, secim değişkeni her değiştiğinde tetiklenecek
    


    const generateRandomNumbers = () => {
        let randomNumbers = [];
        let targetNums = [];
        let max, min;
        
        if (basamakSayisi === 3) {
            min = 100;
            max = 999;
        } else if (basamakSayisi === 4) {
            min = 1000;
            max = 9999;
        }
    
        // TargetNumbers'ı oluştur
        if (secim) {
            while (targetNums.length < bilinmesiGerekenSayiAdeti) {
                const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
                if ((secim === 'TEK' && randomNumber % 2 !== 0) || (secim === 'ÇİFT' && randomNumber % 2 === 0)) {
                    targetNums.push(randomNumber);
                }
            }
        }
        
        // RandomNumbers'ı oluştur ve targetNumbers ile karıştır
        if (secim) {
            while (randomNumbers.length < rows * columns - bilinmesiGerekenSayiAdeti) {
                const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
                // secim'e göre TEK/ÇİFT kontrolü yap
                if ((secim === 'TEK' && randomNumber % 2 === 0) || (secim === 'ÇİFT' && randomNumber % 2 !== 0)) {
                    randomNumbers.push({ value: randomNumber, isVisible: true });
                }
            }
        }
    
        // Target sayıları randomNumbers'a ekle
        targetNums.forEach(num => {
            randomNumbers.splice(Math.floor(Math.random() * randomNumbers.length), 0, { value: num, isVisible: true });
        });
    
        setNumbers(randomNumbers);
        setTargetNumbers(targetNums);
    };
    

    const handleNumberClick = (numberObj, index) => {
        if (isPlaying && numberObj.isVisible) {
            // Kullanıcının tıkladığı sayı targetNumbers listesinde mi kontrol et
            if (targetNumbers.includes(numberObj.value)) {
                // Sayıyı arayüzden kaldır
                setNumbers(numbers.map((num, idx) => idx === index ? { ...num, isVisible: false } : num));
                setBulunanSayilar(prev => prev + 1);
                setToplamBulunan(prevToplam => prevToplam + 1);
    
                // Bulunan sayılar hedefe ulaştı mı kontrol et
                if (bulunanSayilar + 1 === bilinmesiGerekenSayiAdeti) {
                    // Hedefe ulaşıldı, yeni set oluştur
                    setBulunanSayilar(0);
                    setSecim(Math.random() < 0.5 ? 'TEK' : 'ÇİFT');
                    generateRandomNumbers();
                }
            }
        }
    };
    
    

    const handleClickStart = () => {
        setSecim(Math.random() < 0.5 ? 'TEK' : 'ÇİFT');
        generateRandomNumbers();
        setCurrentNumberIndex(0);
        setToplamBulunan(0);
        setBulunanSayilar(0);
        setIsPlaying(true); // Zamanlayıcıyı başlat
        setKey(prevKey => prevKey + 1); // Sayaç süresini sıfırla
        setHasStarted(true);
    };


    /* const handleClickStop = () => {
        setIsPlaying(false); // Zamanlayıcıyı durdur
        setCurrentNumberIndex(0);
    }; */

    // Pause/Play tuşuna basıldığında çağrılacak fonksiyon
    const handlePausePlay = () => {
        setIsPlaying(!isPlaying); // Oyunun oynatma durumunu tersine çevir
    };


    const gitGunler = () => {
        navigate('/');
    };


    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setIsPlaying(false); // Süre bittiğinde zamanlayıcıyı durdur
            return <h4 style={styles.blinkingText}>Süre Doldu</h4>;
        }
        
        return (
          <div className="timer">
            <div className="text">Kalan Süre</div>
            <div className="value">{Math.floor(remainingTime / 60)}:{String(remainingTime % 60).padStart(2, '0')}</div>
            <div className="text">dakika</div>
          </div>
        );
    };



    return (

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

            <div>
                <h1 style={{ margin: '20px' }}><center>Tek mi Çift mi? - {selectedDay}. Gün</center></h1>
                <div style={{ marginBottom: '20px' }}><center><h2>{secim} sayı bul</h2></center></div>
                
                <div style={{ display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`, gap: '5px' }}>
                    {numbers.map((numberObj, index) => {
                        const isTargetNumber = targetNumbers.includes(numberObj.value);
                        const numberStyle = {
                            //color: isTargetNumber ? 'red' : 'black', // Eğer targetNumbers listesindeyse kırmızı, değilse siyah
                            visibility: numberObj.isVisible ? 'visible' : 'hidden'
                        };

                        return (
                            <div
                                key={index}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: isPlaying ? 'pointer' : 'default',
                                    border: '1px solid black',
                                    borderRadius: borderR,
                                }}
                                onClick={() => handleNumberClick(numberObj, index)}
                            >
                                <span style={numberStyle}>
                                    {numberObj.value}
                                </span>
                            </div>
                        );
                    })}
                </div>

                <div style={{ marginTop: '20px', backgroundColor: '#4ad3e7', padding: '10px', borderRadius: '15px', }}>
                    <span><center><h6>Hedef: {bulunanSayilar} / {bilinmesiGerekenSayiAdeti} - Toplam Bulunan: {toplamBulunan}</h6></center></span>
                </div>


                {/* Kontrol Butonları ve Timer */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    
                    {/* Başlat Butonu */}
                    <Button variant="success" onClick={handleClickStart}>Başlat</Button>
                    
                    {/* Timer Wrapper */}
                    <div className="timer-wrapper">
                        <CountdownCircleTimer
                            key={key}
                            isPlaying={isPlaying}
                            duration={verilenSure}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[verilenSure, verilenSure / 2, verilenSure / 3, 0]}
                            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                    
                    {/* Durdur Butonu */}
                    {/* <Button variant="danger" onClick={handleClickStop}>Durdur</Button> */}

                    <Button variant={isPlaying ? "warning" : "info"} onClick={handlePausePlay} disabled={!hasStarted}>
                        {isPlaying ? <HiMiniPause size={30} /> : <HiMiniPlay size={30} />}
                    </Button>
                    
                </div>

                <div style={styles.buttonContainer}>
                    <Button variant="primary" style={{ marginTop: '10px', marginBottom: '50px' }} onClick={gitGunler}>Günler</Button>
                </div>

            </div>

        </div>
        
    );
};

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    blinkingText: {
        margin: '10px',
        animation: 'blinking 1s infinite' // Yanıp sönme efektini uygula
    },
    
};

export default TekCift;


